<template>
  <div class="i-content">
    <v-icon
        medium
        :color="color"
    >
      {{ iconPrimary }}
    </v-icon>
    <v-icon
        x-small
        :class="position === 'bottom' ? inButton === 'true' ? 'i-secondary-bottom-btn' : 'i-secondary-bottom' : inButton === 'true' ? 'i-secondary-top-btn' : 'i-secondary-top'"
        :color="color"
    >
      {{ iconSecond }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'MultiIcons',
  props: {
    iconPrimary: {
      type: String
    },
    iconSecond: {
      type: String
    },
    position: {
      type: String
    },
    color: {
      type: String
    },
    inButton: {
      type: String
    }
  }
}
</script>

<style scoped>
.i-content {
  position: relative;
}
.i-secondary-bottom {
  position: absolute;
  background-color: white;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}
.i-secondary-top {
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  border-radius: 50%;
}
.i-secondary-bottom-btn {
  position: absolute;
  background-color: white;
  bottom: -15px;
  right: -15px;
  border-radius: 50%;
}
.i-secondary-top-btn {
  position: absolute;
  background-color: white;
  top: -15px;
  right: -15px;
  border-radius: 50%;
}
</style>
