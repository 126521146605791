<template>
  <v-tab-item
      :transition="false"
      :reverse-transition="false"
  >
    <v-container
        v-if="isInit"
        fluid
        pa-0
    >
      <v-layout
          row
          class="pyc-6 pxc-6"
      >
        <v-toolbar
            elevation="0"
            dense
            absolute
            height="44"
            class="under-toolbar"
        >
          <v-toolbar-title class="under-toolbar-title">
            {{ $t('reject.card.title') }}
          </v-toolbar-title>
          <v-divider
              vertical
              inset
              class="vertical-divider"
          />
          <span class="px-1 under-toolbar-subtitle">{{ reject.originInvoice }}</span>
          <v-divider
              vertical
              inset
              class="vertical-divider"
          />
          <v-chip
              :color="STATUS_COLOR[reject.status]"
              text-color="white"
              small
              class="statuschip"
          >
            {{ $t('constant.rejectionstatus.' + reject.status) }}
          </v-chip>
          <v-divider
              vertical
              inset
              class="vertical-divider"
          />
          <span
              v-if="$vuetify.breakpoint.lgAndUp"
              class="sub-label"
          >
            {{ updateDate ? $t('updateAt') + updateDate : '' }}
          </span>
          <FieldStates
              :state="state"
              @update="updateRejectStatus"
          />
          <transition name="fade">
            <span
                v-if="message"
                class="px-2 sub-label"
            >
              {{ message }}
            </span>
          </transition>
          <v-spacer v-if="subscribeLoad"/>
          <label
              class="v-label theme--light pr-2 mneg2"
          >
            {{ $t('miscellaneous.comments.subscription') }}
          </label>
          <v-switch
              v-if="subscribeLoad"
              v-model="isSubscribe"
              dense
              hide-details
              class="mt-0"
              @click="updateSubscribe"
          />
          <label
              class="v-label theme--light mneg1"
          >
            {{ isSubscribe ? $t('yes') : $t('no') }}
          </label>
          <FieldStates
              size="normal"
              :state="stateSubscription"
          />
          <v-spacer/>
          <v-btn
              v-if="(canUpdateReject && canCloseManualRejectBeforeTreated && (reject.status == STATUS_ENUM.NEW || reject.status == STATUS_ENUM.IN_PROGRESS)) || (canUpdateReject && canCloseManualTreatedReject && reject.status == STATUS_ENUM.TREATED)"
              :disabled="!canUpdateReject"
              color="red"
              class="mr-2"
              small
              @click="sendClosed"
          >
            {{ $t('reject.sendclosed') }}
          </v-btn>
          <v-btn
              v-if="canUpdateReject && reject.status == STATUS_ENUM.NEW"
              :disabled="!canUpdateReject"
              color="teal"
              class="mr-2"
              small
              @click="updateRejectStatus(STATUS_ENUM.IN_PROGRESS)"
          >
            {{ $t('reject.sendInProgress') }}
          </v-btn>
          <v-btn
              v-if="canUpdateReject && (reject.status == STATUS_ENUM.NEW || reject.status == STATUS_ENUM.IN_PROGRESS)"
              :disabled="!canUpdateReject"
              color="green"
              small
              @click="sendResolution"
          >
            {{ $t('reject.sendresolved') }}
          </v-btn>
          <ResolveReject
              v-if="resolvePopup"
              :value="value"
              :reject="reject"
              :documents="documents"
              @close="resolvePopup = false"
              @resolveConfirm="updateState"
          />

          <v-menu
              v-if="canDeleteReject"
              transition="slide-y-transition"
              bottom
          >
            <template #activator="{ on }">
              <v-btn
                  icon
                  height="40"
                  width="40"
                  v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  @click="confirmDelete()"
              >
                {{ $t('reject.delete.menu') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-container
            fluid
            grid-list-xl
            px-3
            py-2
        >
          <v-row no-gutters>
            <v-col
                cols="6"
                class="py-0"
            >
              <v-row no-gutters>
                <v-col
                    cols="12"
                    sm="12"
                    class="py-0"
                >
                  <RejectInformations
                      :value="reject.id"
                      :read-only="readOnly"
                      :reject-props="reject"
                  />
                </v-col>
                <v-col
                    cols="12"
                    class="py-0"
                >
                  <RejectActions
                      class="mt-3"
                      :value="reject.id"
                      :read-only="readOnly"
                      :actions-props="rejectActions"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
                cols="6"
                class="py-0"
            >
              <v-row no-gutters>
                <v-col
                    cols="12"
                    sm="12"
                    class="py-0"
                >
                  <RejectCommunications
                      :value="reject.id"
                      :read-only="readOnly"
                      :messages-props="rejectMessages"
                      :reject-props="reject"
                  />
                  <Comments
                      v-if="canViewComment"
                      service-name="rejections"
                      :value="value"
                      :title="'miscellaneous.comments.title'"
                      class="mt-3"
                  />
                  <RejectDocuments
                      class="mt-3"
                      :value="reject.id"
                      :read-only="readOnly"
                      @documentData="handleDocuments"
                  />
                  <Cascade
                      class="mt-3"
                      :value="reject.id"
                      :title="'reject.cascade.title'"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
    </v-container>
    <ConfirmDialog ref="confirm"/>
    <v-snackbar
        v-model="snackbar"
        multi-line
    >
      <div v-html="messageSnackbar"/>

      <template #action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          {{ $t('button.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-tab-item>
</template>

<script>
import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import omitFunctions from '@/mixins/omitFunctions'
import { INPUT_STATES, STATUS_COLOR, STATUS_ENUM } from '@/plugins/constants'
import fieldHandler from '@/mixins/fieldHandler'
import FieldStates from '@/components/general/fields/FieldStates'
import rejectService from '@/services/reject/rejectService'
import moment from 'moment'
import RejectInformations from '@/components/reject/RejectInformations'
import RejectCommunications from '@/components/reject/communication/RejectCommunications'
import RejectActions from '@/components/reject/actions/RejectActions'
import ResolveReject from '@/components/reject/ResolveReject'
import Comments from '@/components/miscellaneous/comments/Comments'
import subscriptionService from '@/services/notification/subscriptionService'
import RejectDocuments from '@/components/reject/documents/RejectDocuments'
import Cascade from '@/components/reject/cascade/CascadeCard.vue'

export default {
  name: 'RejectCard',
  components: {
    Cascade,
    ResolveReject,
    RejectInformations,
    RejectDocuments,
    RejectCommunications,
    RejectActions,
    FieldStates,
    ConfirmDialog,
    Comments
  },
  mixins: [omitFunctions, fieldHandler],
  props: {
    value: {},
    tabIndex: {
      default: 0
    }
  },
  data: () => ({
    documents: [],
    isInit: false,
    readOnly: false,
    reject: {},
    rejectActions: [],
    rejectMessages: [],
    STATUS_COLOR: STATUS_COLOR,
    message: '',
    messageSnackbar: '',
    resolvePopup: false,
    updateDate: '',
    state: INPUT_STATES.NONE,
    stateSubscription: INPUT_STATES.NONE,
    INPUT_STATES: INPUT_STATES,
    STATUS_ENUM: STATUS_ENUM,
    updatedFlag: false,
    version: 0,
    snackbar: false,
    isSubscribe: false,
    subscribeLoad: false
  }),
  computed: {
    ...mapGetters({
      me: 'user',
      getCanSeeReject: 'privileges/reject/canSeeReject',
      canUpdateReject: 'privileges/reject/canUpdateReject',
      getCanDeleteReject: 'privileges/reject/canDeleteReject',
      getCanCloseManualRejectBeforeTreated: 'privileges/reject/canCloseManualRejectBeforeTreated',
      getCanCloseManualTreatedReject: 'privileges/reject/canCloseManualTreatedReject',
      canViewComment: 'privileges/comment/canViewComment'
    }),
    canSeeReject () {
      const canI = _.find(this.getCanSeeReject, ['id', this.value])
      return canI ? canI.privilege : false
    },
    canDeleteReject () {
      const canI = _.find(this.getCanDeleteReject, ['id', this.value])
      return canI ? canI.privilege : false
    },
    canCloseManualRejectBeforeTreated () {
      const canI = _.find(this.getCanCloseManualRejectBeforeTreated, ['id', this.value])
      return canI ? canI.privilege : false
    },
    canCloseManualTreatedReject () {
      const canI = _.find(this.getCanCloseManualTreatedReject, ['id', this.value])
      return canI ? canI.privilege : false
    },
    tabId () {
      return this.$router.resolve({
        name: 'reject-card',
        params: { id: this.value }
      }).href
    }
  },
  created () {
    this.$rt.subscribe('/rejections/' + this.value, this.onRealTimeUpdateField)
    this.$rt.subscribe('/subscriptions/' + this.me.id + '/' + this.value, this.onRealTimeUpdateSubscription)
    this.messageSnackbar = this.$t('reject.snackbar.message')
    rejectService.getReject(this.value, ['customer', 'treatedBy', 'tags', 'prices', 'messages', 'tasks']).then((response) => {
      this.reject = response
      this.rejectActions = this.reject.tasks
      this.rejectMessages = this.reject.messages
      this.updateReadOnly()
      this.isInit = true
      this.updateDate = moment(this.reject.updateDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
      this.updateTab({
        tabId: this.tabId,
        name: this.reject.originInvoice
      })
    }).catch(() => {
      console.log('CARD CLOSE 1')
      this.closeTabById(this.tabId)
    })
    this.getSubscription()
    this.checkPrivileges()
  },
  beforeDestroy () {
    this.$rt.unsubscribe('/rejections/' + this.value, this.onRealTimeUpdateField)
    this.$rt.unsubscribe('/subscriptions/' + this.me.id + '/' + this.value, this.onRealTimeUpdateSubscription)
  },
  methods: {
    ...mapActions({
      setCanReadReject: 'privileges/reject/setCanReadReject',
      setCanDeleteReject: 'privileges/reject/setCanDeleteReject',
      setCanCloseManualRejectBeforeTreated: 'privileges/reject/setCanCloseManualRejectBeforeTreated',
      setCanCloseManualTreatedReject: 'privileges/reject/setCanCloseManualTreatedReject',
      closeTabById: 'closeTabById',
      updateTab: 'updateTab'
    }),
    updateReadOnly () {
      if (this.reject.status == STATUS_ENUM.TREATED || this.reject.status == STATUS_ENUM.CLOSED) {
        this.readOnly = true
      } else {
        this.readOnly = false
      }
    },
    getSubscription () {
      subscriptionService.isSubscribe(this.value).then(response => {
        this.isSubscribe = response
        this.subscribeLoad = true
      })
    },
    updateSubscribe () {
      if (this.subscribeLoad) {
        if (this.isSubscribe) {
          subscriptionService.subscribe(this.value).then(response => {
            this.updateStateSubscription()
          })
        } else {
          subscriptionService.unsubscribe(this.value).then(response => {
            this.updateStateSubscription()
          })
        }
      }
    },
    sendResolution () {
      if (this.reject.onOrOff == 'NONE' || this.reject.qualification == 'NONE' || this.reject.treatedBy?.id == undefined || this.reject.customer?.id == undefined) {
        this.snackbar = true
      } else {
        if (this.reject.status == STATUS_ENUM.NEW || this.reject.status == STATUS_ENUM.IN_PROGRESS) {
          this.resolvePopup = true
        }
      }
    },
    sendClosed () {
      if (this.reject.onOrOff == 'NONE' || this.reject.qualification == 'NONE' || this.reject.treatedBy?.id == undefined || this.reject.customer?.id == undefined) {
        this.snackbar = true
      } else {
        if (this.reject.status == STATUS_ENUM.NEW || this.reject.status == STATUS_ENUM.IN_PROGRESS) {
          if (this.canUpdateReject && this.canCloseManualRejectBeforeTreated) {
            rejectService.closeReject(this.value)
          }
        } else if (this.reject.status == STATUS_ENUM.TREATED) {
          if (this.canUpdateReject && this.canCloseManualTreatedReject) { rejectService.closeReject(this.value) }
        }
      }
    },
    updateRejectStatus (newStatus) {
      if (this.canUpdateReject) {
        this.update(this, rejectService.updateReject, {
          version: this.reject.version,
          fields: { status: newStatus }
        })
      }
    },
    checkPrivileges () {
      const payload = {
        me: this.me,
        rejectId: this.value
      }
      this.setCanReadReject(payload).then(() => {
        if (!this.canSeeReject) {
          this.closeTabById(this.tabId)
        }
      })
      this.setCanDeleteReject(payload)
      this.setCanCloseManualRejectBeforeTreated(payload)
      this.setCanCloseManualTreatedReject(payload)
    },
    onRealTimeUpdateSubscription (update) {
      this.updateStateSubscription()
    },
    onRealTimeUpdateField (update) {
      const response = JSON.parse(update)
      this.updateDate = response.dateUpdate
      this.reject.version = response.version

      // console.log('onRealTimeUpdateField', response)
      if (_.has(response.fields, 'actions')) {
        this.rejectActions = response.fields.actions
      }
      if (_.has(response.fields, 'messages')) {
        this.rejectMessages = response.fields.messages
      }
      if (_.has(response.fields, 'treatedBy')) {
        this.reject.treatedBy = response.fields.treatedBy
      }
      if (_.has(response.fields, 'qualification')) {
        this.reject.qualification = response.fields.qualification
      }
      if (_.has(response.fields, 'resolutionMessage')) {
        this.reject.resolutionMessage = response.fields.resolutionMessage
      }
      if (_.has(response.fields, 'status')) {
        this.reject.status = response.fields.status
        this.updateReadOnly()
      }
      if (_.has(response.fields, 'customer')) {
        this.reject.customer = response.fields.customer
      }
      if (_.has(response.fields, 'onOrOff')) {
        this.reject.onOrOff = response.fields.onOrOff
      }
    },
    updateState () {
      this.state = INPUT_STATES.SAVED
      this.resetState()
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    }, 10000),
    updateStateSubscription () {
      this.getSubscription()
      this.stateSubscription = INPUT_STATES.SAVED
      this.resetStateSubscription()
    },
    resetStateSubscription: _.debounce(function () {
      this.stateSubscription = INPUT_STATES.NONE
    }, 10000),
    confirmDelete () {
      this.$refs.confirm.open(
        this.$t('reject.delete.confirmDelete.title'), this.$t('reject.delete.confirmDelete.areYouSure')
      ).then((confirm) => {
        if (confirm) this.delete()
      })
    },
    delete () {
      this.state = INPUT_STATES.LOADING
      rejectService.deleteReject(this.reject.id).then(() => {
        this.state = INPUT_STATES.SAVED
        this.message = ''
        this.closeTabById(this.tabId)
      }).catch(() => {
        this.state = INPUT_STATES.ERROR
        this.message = this.$t('reject.delete.error')
        this.resetState()
      })
    },
    handleDocuments (data) {
      this.documents = data
    }
  }
}
</script>

<style scoped>
.v-toolbar {
  background-color: white;
}

.tabs-line {
  width: 500px;
  max-width: 60%;
  height: 43px !important;
}

.tabWrapper {
  height: 100%;
}

.v-avatar--left {
  margin-right: 5px
}

.v-chip >>> .v-chip__content {
  font-weight: 600 !important;
  font-size: 0.8rem !important;
}

.mneg1 {
  margin-top: -1px;
}

.mneg2 {
  margin-top: -2px;
}
</style>
