var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canSeeStatistics)?_c('v-tab-item',{attrs:{"transition":false,"reverse-transition":false}},[_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-layout',{staticClass:"pyc-6 pxc-6",attrs:{"row":""}},[_c('v-toolbar',{staticClass:"under-toolbar",attrs:{"elevation":"0","dense":"","absolute":"","height":"44"}},[_c('v-toolbar-title',{staticClass:"under-toolbar-title"},[_vm._v(" "+_vm._s(_vm.$t('statistics.general.toolbarTitle'))+" ")]),_c('v-spacer')],1),_c('v-container',{attrs:{"fluid":"","grid-list-xl":"","px-3":"","py-2":""}},[_c('v-card',{attrs:{"flat":"","loading":_vm.statusDataLoader}},[_c('v-card-title',{staticClass:"pl-5"},[_c('span',{staticClass:"mr-3 card-title"},[_vm._v(_vm._s(_vm.$t('statistics.general.export.titleStatus')))]),_c('v-spacer'),(_vm.canSeeAdministration)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.downloadStatusStats()}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-spacer'),_c('v-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("statistics.general.filters"))+" ")])]),_c('v-col',{attrs:{"sm":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","range":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('statistics.general.export.dates'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeTextStatusData),callback:function ($$v) {_vm.dateRangeTextStatusData=$$v},expression:"dateRangeTextStatusData"}},'v-text-field',attrs,false),on))]}}],null,false,1302993899),model:{value:(_vm.menuStatusData),callback:function ($$v) {_vm.menuStatusData=$$v},expression:"menuStatusData"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){_vm.menuStatusData = false; _vm.getStatusData()}},model:{value:(_vm.datesStatusData),callback:function ($$v) {_vm.datesStatusData=$$v},expression:"datesStatusData"}})],1)],1),_c('v-col',{attrs:{"sm":"4"}},[_c('v-combobox',{staticClass:"mt-2",attrs:{"items":_vm.statStatus,"item-text":"customerName","label":_vm.$t('statistics.dataTable.client'),"clearable":"","multiple":"","small-chips":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item.customerName))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" "+_vm._s(_vm.$tc('statistics.general.others', _vm.searchStatusData.length - 1, { number: _vm.searchStatusData.length - 1 }))+" ")]):_vm._e()]}}],null,false,2606804400),model:{value:(_vm.searchStatusData),callback:function ($$v) {_vm.searchStatusData=$$v},expression:"searchStatusData"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersStatus,"items":_vm.filteredStatStatus,"items-per-page":_vm.statStatus.length,"height":"420px","multi-sort":"","hide-default-footer":"","disable-pagination":"","fixed-header":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":_vm.canSeeAdministration ? 7 : 12}},[_c('v-card',{staticClass:"mt-1",attrs:{"flat":"","loading":_vm.tagsDataLoader}},[_c('v-card-title',{staticClass:"pl-5"},[_c('span',{staticClass:"mr-3 card-title"},[_vm._v(_vm._s(_vm.$t('statistics.general.export.titleTags')))]),_c('v-spacer'),(_vm.canSeeAdministration)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.downloadTagsStatsOneColumn()}}},'v-btn',attrs,false),on),[_c('MultiIcons',{attrs:{"icon-primary":"mdi-file-tree","icon-second":"mdi-download","position":"bottom","in-button":"true"}})],1)]}}],null,false,2097004460)},[_c('span',[_vm._v(_vm._s(_vm.$t('statistics.general.export.tooltip.monoColumn')))])]):_vm._e(),(_vm.canSeeAdministration)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2 ml-4",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.downloadTagsStatsMultiColumns()}}},'v-btn',attrs,false),on),[_c('MultiIcons',{attrs:{"icon-primary":"mdi-reorder-vertical","icon-second":"mdi-download","position":"bottom","in-button":"true"}})],1)]}}],null,false,329523265)},[_c('span',[_vm._v(_vm._s(_vm.$t('statistics.general.export.tooltip.multiColumn')))])]):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-spacer'),_c('v-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("statistics.general.filters"))+" ")])]),_c('v-col',{attrs:{"sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","range":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('statistics.general.export.dates'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeTextTagsData),callback:function ($$v) {_vm.dateRangeTextTagsData=$$v},expression:"dateRangeTextTagsData"}},'v-text-field',attrs,false),on))]}}],null,false,1657405502),model:{value:(_vm.menuTagsData),callback:function ($$v) {_vm.menuTagsData=$$v},expression:"menuTagsData"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){_vm.menuTagsData = false; _vm.getTagsData()}},model:{value:(_vm.datesTagsData),callback:function ($$v) {_vm.datesTagsData=$$v},expression:"datesTagsData"}})],1)],1),_c('v-col',{attrs:{"sm":"5"}},[_c('v-combobox',{staticClass:"mt-2",attrs:{"items":_vm.statTags,"item-text":"customer","label":_vm.$t('statistics.dataTable.client'),"clearable":"","multiple":"","small-chips":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item.customer))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" "+_vm._s(_vm.$tc('statistics.general.others', _vm.searchTagsData.length - 1, { number: _vm.searchTagsData.length - 1 }))+" ")]):_vm._e()]}}],null,false,1370838967),model:{value:(_vm.searchTagsData),callback:function ($$v) {_vm.searchTagsData=$$v},expression:"searchTagsData"}})],1)],1),_c('v-data-table',{attrs:{"expanded":_vm.expanded,"headers":_vm.headersTags,"items":_vm.filteredStatTags,"items-per-page":_vm.statTags.length,"item-key":"customer","hide-default-footer":"","disable-pagination":"","show-expand":"","disable-sort":"","height":"371px","fixed-header":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.tags),function(tag,index){return _c('div',{key:index},[_c('v-list-item',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"sm":"5"}},[_c('span',[_vm._v(_vm._s(tag.first))])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"sm":"6"}},[_c('span',[_vm._v(_vm._s(tag.second))])])],1)],1)],1)}),0)]}}],null,false,2618141419)})],1)],1)],1),_c('v-col',{attrs:{"sm":"5"}},[(_vm.canSeeAdministration)?_c('v-card',{staticClass:"mt-1",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pl-5"},[_c('span',{staticClass:"mr-3 card-title"},[_vm._v(_vm._s(_vm.$t('statistics.general.export.title')))])]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"sm":"7"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('statistics.general.export.dates'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeTextExportData),callback:function ($$v) {_vm.dateRangeTextExportData=$$v},expression:"dateRangeTextExportData"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"offset":"6","sm":"6"}},[(_vm.canSeeAdministration)?_c('v-btn',{attrs:{"disabled":_vm.dates.length != 2,"elevation":"2","loading":_vm.loading},on:{"click":_vm.exportStats}},[_vm._v(" "+_vm._s(_vm.$t('statistics.general.export.button'))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }