<template>
  <v-card flat>
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{
          $t('administration.general.filesimported.title')
        }}</span>
      <FieldStates
          :state="state"
      />
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('placeholder.search')"
          class="pt-0 mt-0 mr-8"
          single-line
          hide-details
      />
      <v-btn
          v-if="canCreateFileImported"
          class="green-background ml-1 mx-2"
          icon
          dark
          small
          @click="uploadDialog = true"
      >
        <v-icon>mdi-playlist-plus</v-icon>
      </v-btn>
      <v-btn
          v-if="canCreateFileImported"
          class="green-background"
          icon
          dark
          small
          @click="addItem()"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <AddFileImported
          v-if="dialog"
          :item-to-edit="itemToEdit"
          @close="dialog = false"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6 pt-2">
      <v-data-table
          :headers="headers"
          :items="fileimportedList"
          :options.sync="options"
          :server-items-length="totalFilesimported"
          :loading="loading"
          :items-per-page="50"
          :footer-props="{'items-per-page-options': [25, 50, 75, 100]}"
      >
        <template #item.nbCreated="{ item }">
          <v-icon class="blue--text text--darken-3">
            mdi-archive-plus
          </v-icon>
          {{ item.nbCreated }}
          <span> / </span>
          <v-icon class="green--text text--darken-3">
            mdi-check-decagram
          </v-icon>
          {{ item.nbReconciliation }}
        </template>
        <template #item.actions="{ item }">
          <v-btn
              class="mr-1"
              icon
              @click.stop="editItem(item)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <DragDropUploadFile
        :dialog.sync="uploadDialog"
        :multiple="true"
        :sentence="$t('miscellaneous.dragDropUpload.sentence') + (folderSelected[0] ? '<br>Répertoire : ' + folderSelected[0].name : '')"
        @filesUploaded="processUpload($event)"
    />
    <ProgressBarUploadFile
        :file-saved="fileSaved"
        :file-to-save="fileToSave"
        :dialog.sync="progressBarDialog"
    />
    <NotImportedFiles
        :files-not-imported="filesNotImported"
        :dialog.sync="notImportedFilesDialog"
    />
  </v-card>
</template>

<script>
import fileimportedService from '@/services/fileimported/fileimportedService'
import AddFileImported from '@/components/administration/fileImported/add/AddFileImported'
import ProgressBarUploadFile from '@/components/administration/fileImported/progressBar/ProgressBarUploadFile.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { INPUT_STATES } from '@/plugins/constants'
import DragDropUploadFile from '@/components/administration/fileImported/add/DragDropUploadFile.vue'
import FieldStates from '@/components/general/fields/FieldStates.vue'
import NotImportedFiles from '@/components/administration/fileImported/progressBar/NotImportedFiles.vue'

export default {
  name: 'Fileimporteds',
  components: {
    NotImportedFiles,
    ProgressBarUploadFile,
    FieldStates,
    DragDropUploadFile,
    AddFileImported
  },
  data () {
    return {
      fileSaved: 0,
      fileToSave: 0,
      filesNotImported: [],
      state: INPUT_STATES.NONE,
      canUpload: true,
      notImportedFilesDialog: false,
      uploadDialog: false,
      progressBarDialog: false,
      folderSelected: [],
      search: '',
      itemToEdit: {},
      fileimportedList: [],
      loading: true,
      options: {},
      totalFilesimported: 0,
      headers: [
        {
          text: this.$t('administration.general.name'),
          value: 'name'
        },
        {
          text: this.$t('administration.general.createDate'),
          value: 'createDate'
        },
        {
          text: this.$t('administration.general.rejections'),
          value: 'nbCreated'
        },
        {
          text: this.$t('administration.general.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      canSeeFileImported: 'privileges/filesimported/canSeeFileImported',
      canCreateFileImported: 'privileges/filesimported/canCreateFileImported'
    })
  },
  watch: {
    options: {
      handler () {
        this.getFilesimported()
      },
      deep: true
    },
    search: {
      handler () {
        this.getFilesimported(true)
      },
      deep: true
    }
  },
  created () {
    this.$rt.subscribe('/importedfiles/all', this.onRealTimeUpdate)
    this.getFilesimported = _.debounce(this.getFilesimported, 1000)
    this.getFilesimported(true)
  },
  beforeDestroy () {
    this.$rt.unsubscribe('/importedfiles/all', this.onRealTimeUpdate)
  },
  methods: {
    getFilesimported (startPageZero = false) {
      this.loading = true
      if (this.canSeeFileImported) {
        const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
        } = this.options
        let currentPage = page - 1
        if (startPageZero) {
          currentPage = 0
          this.options.page = currentPage + 1
        }
        fileimportedService.getAllFilesimported(currentPage, itemsPerPage, sortBy, sortDesc, this.search).then(response => {
          this.fileimportedList = response.content
          this.totalFilesimported = response.totalElements
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    addItem () {
      this.itemToEdit = {}
      this.dialog = true
    },
    editItem (item) {
      this.itemToEdit = item
      this.dialog = true
    },
    processUpload (files) {
      this.filesNotImported = []
      this.fileSaved = 0
      this.fileToSave = files.length
      for (const f in files) {
        this.state = INPUT_STATES.LOADING
        this.uploadDocument(files[f])
        this.progressBarDialog = true
      }
    },
    uploadDocument (file) {
      if (this.canUpload) {
        this.canUpload = false
        fileimportedService.saveFileImported(file).then(response => {
          this.canUpload = true
          this.fileSaved += 1
          if (this.fileSaved === this.fileToSave) {
            this.progressBarDialog = false
            if (this.filesNotImported.length !== 0) {
              this.notImportedFilesDialog = true
              this.state = INPUT_STATES.ERROR
            } else {
              this.state = INPUT_STATES.SAVED
            }
            this.resetState()
          }
        }).catch((error) => {
          if (error.message.split(' ').at(-1) === '400') {
            file.error = this.$t('administration.general.filesimported.error.Duplicate')
          } else {
            file.error = this.$t('administration.general.filesimported.error.Other')
          }
          this.canUpload = true
          console.log('Document : Erreur create : ' + error)
          this.fileToSave -= 1
          this.filesNotImported.push(file)
          if (this.fileSaved === this.fileToSave) {
            this.progressBarDialog = false
            if (this.filesNotImported.length !== 0) {
              this.notImportedFilesDialog = true
              this.state = INPUT_STATES.ERROR
            } else {
              this.state = INPUT_STATES.SAVED
            }
            this.resetState()
          }
        })
      } else {
        setTimeout(() => {
          this.uploadDocument(file)
        }, 100)
      }
    },
    onRealTimeUpdate (update) {
      const response = JSON.parse(update)
      this.options.page = 1
      this.fileimportedList = response.list
      this.totalFilesimported = response.totalElements
      this.search = ''
      this.options.itemsPerPage = 50
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
    }, 10000)
  }
}
</script>
