<template>
  <v-dialog
      :value="dialog"
      max-width="450px"
      @click:outside="closeDialog"
  >
    <v-card
        :class="{ 'grey lighten-2': dragover }"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
    >
      <v-card-title>
        <v-row
            class="d-flex flex-column"
            dense
            align="end"
        >
          <v-btn
              icon
              @click="closeDialog"
          >
            <v-icon id="close-button2">
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
            class="d-flex flex-column"
            dense
            align="center"
            justify="center"
        >
          <v-icon
              :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
              size="60"
          >
            mdi-cloud-upload
          </v-icon>
          <p
              align="center"
              v-html="sentence"
          />
        </v-row>
        <v-virtual-scroll
            v-if="uploadedFiles.length > 0"
            :items="uploadedFiles"
            height="150"
            item-height="50"
        >
          <template #default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                  <span class="ml-3 text--secondary">
                    {{ item.size }} bytes</span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                    icon
                    @click.stop="removeFile(item.name)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider/>
          </template>
        </v-virtual-scroll>
        <ConfirmDialog ref="confirm"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            :disabled="uploadedFiles.length === 0"
            @click.stop="confirmAddDoc"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog.vue'

export default {
  name: 'DragDropUploadFile',
  components: {
    ConfirmDialog
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    sentence: {
      type: String,
      default: ''
    },
    alertMessage: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      dragover: false,
      uploadedFiles: []
    }
  },

  methods: {
    closeDialog () {
      // Remove all the uploaded files
      this.uploadedFiles = []
      // Close the dialog box
      this.$emit('update:dialog', false)
    },

    removeFile (fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(file => file.name === fileName)

      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1)
    },

    onDrop (e) {
      this.dragover = false

      // If there are already uploaded files remove them
      if (this.uploadedFiles.length > 0) this.uploadedFiles = []

      // If user has uploaded multiple files but the component is not multiple get first
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.uploadedFiles.push(e.dataTransfer.files[0])
      } else {
        // Add each file to the array of uploaded files
        e.dataTransfer.files.forEach(element => this.uploadedFiles.push(element))
      }
    },

    confirmAddDoc () {
      if (this.alertMessage) {
        this.$refs.confirm.open(
          this.$t('person.documents.addDocument.title'), this.alertMessage
        ).then((confirm) => {
          if (confirm) {
            this.submit()
          }
        })
      } else {
        this.submit()
      }
    },

    submit () {
      // Send uploaded files to parent component
      this.$emit('filesUploaded', this.uploadedFiles)
      // Close the dialog box
      this.closeDialog()
    }
  }
}
</script>

<style scoped>

</style>
