import axios from 'axios'
import moment from 'moment/moment'

export default {
  async getStatusData (startDate, endDate) {
    const { data } = await axios.get(`/statistics/statusdata?startDate=${startDate || ''}&endDate=${endDate || ''}`)
    return data
  },

  async getTagsData (startDate, endDate) {
    const { data } = await axios.get(`/statistics/tagsdata?startDate=${startDate || ''}&endDate=${endDate || ''}`)
    return data
  },

  async downloadStatusStats (startDate, endDate, selected) {
    let selectedUrl = ''
    for (let i = 0; i < selected.length; i++) {
      selectedUrl += i === 0 ? selected[i] : '+' + selected[i]
    }
    const response = await axios.get('/statistics/exportstatusdata',
      {
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=utf-8'
        },
        responseType: 'blob',
        params: {
          startDate: startDate || '',
          endDate: endDate || '',
          selected: selectedUrl
        }
      })
    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)

    const dateNow = moment().format('DDMMYYYY-HHmmss')
    const dateStart = moment(startDate).format('DDMMYYYY')
    const dateEnd = moment(endDate).format('DDMMYYYY')

    if (startDate != null && endDate != null) {
      link.setAttribute('download', 'rops_status_statistics_reports_' + dateNow + '_from_' + dateStart + '_to_' + dateEnd + '.xlsx')
    } else {
      link.setAttribute('download', 'rops_status_statistics_reports_' + dateNow + '.xlsx')
    }
    document.body.appendChild(link)
    link.click()
    return response
  },

  async downloadTagsStatsOneColumn (startDate, endDate, selected) {
    let selectedUrl = ''
    for (let i = 0; i < selected.length; i++) {
      selectedUrl += i === 0 ? selected[i] : '+' + selected[i]
    }
    const response = await axios.get('/statistics/exportmonotagsdata',
      {
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=utf-8'
        },
        responseType: 'blob',
        params: {
          startDate: startDate || '',
          endDate: endDate || '',
          selected: selectedUrl
        }
      })
    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)

    const dateNow = moment().format('DDMMYYYY-HHmmss')
    const dateStart = moment(startDate).format('DDMMYYYY')
    const dateEnd = moment(endDate).format('DDMMYYYY')

    if (startDate != null && endDate != null) {
      link.setAttribute('download', 'rops_tags_statistics_reports_' + dateNow + '_from_' + dateStart + '_to_' + dateEnd + '.xlsx')
    } else {
      link.setAttribute('download', 'rops_tags_statistics_reports_' + dateNow + '.xlsx')
    }
    document.body.appendChild(link)
    link.click()
    return response
  },

  async downloadTagsStatsMultiColumns (startDate, endDate, selected) {
    let selectedUrl = ''
    for (let i = 0; i < selected.length; i++) {
      selectedUrl += i === 0 ? selected[i] : '+' + selected[i]
    }
    const response = await axios.get('/statistics/exportmultitagsdata',
      {
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=utf-8'
        },
        responseType: 'blob',
        params: {
          startDate: startDate || '',
          endDate: endDate || '',
          selected: selectedUrl
        }
      })
    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)

    const dateNow = moment().format('DDMMYYYY-HHmmss')
    const dateStart = moment(startDate).format('DDMMYYYY')
    const dateEnd = moment(endDate).format('DDMMYYYY')

    if (startDate != null && endDate != null) {
      link.setAttribute('download', 'rops_tags_statistics_reports_' + dateNow + '_from_' + dateStart + '_to_' + dateEnd + '.xlsx')
    } else {
      link.setAttribute('download', 'rops_tags_statistics_reports_' + dateNow + '.xlsx')
    }
    document.body.appendChild(link)
    link.click()
    return response
  }
}
