<template>
  <v-tab-item
      :transition="false"
      :reverse-transition="false"
  >
    <v-container
        fluid
        pa-0
    >
      <v-toolbar
          elevation="0"
          dense
          absolute
          height="44"
          class="under-toolbar"
      >
        <v-toolbar-title class="under-toolbar-title">
          {{ $t('dashboard.subtitle') }}
        </v-toolbar-title>
        <v-divider
            vertical
            inset
            class="vertical-divider"
        />
        <FieldStates
            class="fieldStates"
            :state="state"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
        <span
            v-if="itemsTotalNumber > 0 && !dataLoader"
            class="under-toolbar-subtitle"
        >
          {{ $t('dashboard.loadedRejectsNumber', { number: itemsLoadedNumber, total: itemsTotalNumber }) }}
          <span
              v-if="filters.search"
              class="under-toolbar-subtitle pa-0"
          >
            {{ $tc('dashboard.loadedRejectsFound', itemsTotalNumber) }}
          </span>
        </span>
        <span
            v-else-if="!dataLoader"
            class="under-toolbar-subtitle"
        >
          {{ $t('dashboard.searchNoResults') }}
        </span>
        <span
            v-if="dataLoader"
            class="under-toolbar-subtitle"
        >
          <v-progress-circular
              indeterminate
              :width="2"
              :size="20"
          />
        </span>
        <v-spacer/>
        <v-text-field
            v-if="canSeeRejectsList"
            v-model="filters.search"
            prepend-icon="mdi-magnify"
            :label="$t('dashboard.search')"
            class="searchbar ma-1"
            single-line
            dense
            hide-details
        >
          <template #append>
            <RejectAdvanceSearch
                :filters="advanceFilters"
                @setClear="setClear"
                @setResearch="setResearch"
            />
          </template>
        </v-text-field>
        <v-btn
            v-if="canSeeRejectsList"
            icon
            height="40"
            width="40"
            @click.stop="drawerFilter = !drawerFilter"
        >
          <v-icon>
            mdi-filter-variant
          </v-icon>
        </v-btn>
        <v-btn
            v-if="canSeeRejectsList"
            icon
            height="40"
            width="40"
            @click.stop="loadData(0)"
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
        <!-- <v-btn
            icon
            height="40"
            width="40"
            :disabled="!canCreateReject"
            color="green"
            @click="dialogCreateReject = true"
        >
          <v-icon>
            mdi-wallet-plus-outline
          </v-icon>
        </v-btn> -->
      </v-toolbar>
      <v-container
          fluid
          grid-list-xl
          class="main-vue-container"
          px-3
          py-2
      >
        <v-layout
            row
            class="pyc-6"
        >
          <v-flex class="data-box pxc-6">
            <v-card
                v-if="canSeeRejectsList"
                elevation="0"
            >
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="10">
                    <div class="table-filters-box">
                      {{ $t('dashboard.filters.title') }}&nbsp;:
                      <span
                          v-if="noFilters"
                          class="ma-2 d-inline-block"
                      >
                      {{ $t('dashboard.filters.noFilters') }}
                    </span>
                      <v-chip
                          v-for="tableChip in tableChips"
                          :key="tableChip.name"
                          close
                          outlined
                          small
                          class="ma-2"
                          color="#444469"
                          @click:close="closeTableChip(tableChip)"
                      >
                        {{ tableChip.name }}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col
                      cols="2"
                      class="d-flex flex-row-reverse"
                  >
                    <v-switch
                        v-model="hideClosed"
                        dense
                        hide-details
                        class="mt-0 pt-2"
                        @change="updateHideClosed"
                    />
                    <label
                        class="v-label theme--light pt-3 pr-2 mneg2"
                    >
                      {{ $t('dashboard.hideclosed') }}
                    </label>
                  </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="rejects"
                    :items-per-page="rejects.length"
                    class="striped"
                    multi-sort
                    disable-filtering
                    :no-data-text="$t('dashboard.dataTable.noDataSearch')"
                    hide-default-footer
                    :options.sync="dataTableOptions"
                    :custom-sort="disableCustomSort"
                    :loading="dataLoader"
                >
                  <template #item="props">
                    <tr
                        :key="props.index"
                        @dblclick="openRejectDetailsTab(props.item.id)"
                    >
                      <td
                          v-for="(header, index) in headers"
                          :key="index"
                          class="dashboard-td"
                      >
                        <div v-if="header.value == 'originInvoice'">
                          <div v-if="props.item.originInvoice != null">
                            <strong class="strong-class">
                              {{ props.item.originInvoice }}
                            </strong>
                          </div>
                          <div v-else>
                            {{ $t('emptyColumn') }}
                          </div>
                        </div>
                        <div v-else-if="header.value == 'status'">
                          <div v-if="props.item.status != null">
                            <v-chip
                                :color="STATUS_COLOR[props.item.status]"
                                text-color="white"
                                small
                                class="statuschip"
                            >
                              {{ $t('constant.rejectionstatus.'+props.item.status) }}
                            </v-chip>
                          </div>
                          <div v-else>
                            {{ $t('emptyColumn') }}
                          </div>
                        </div>
                        <div v-else-if="header.value == 'tags'">
                          <div v-if="props.item.tags != null">
                            <v-chip
                                v-for="tag in props.item.tags"
                                :key="'tagkey'+tag.id"
                                x-small
                                class="tagchip"
                            >
                              {{ tag.name }}
                            </v-chip>
                          </div>
                          <div v-else>
                            {{ $t('emptyColumn') }}
                          </div>
                        </div>
                        <div v-else-if="header.value == 'createDate'">
                          <div>{{ formatDate(props.item.createDate) }}</div>
                          <div>{{ formatHour(props.item.createDate) }}</div>
                        </div>
                        <div v-else-if="header.value == 'qualification'">
                          <div v-if="props.item.qualification != null">
                            {{ $t('constant.rejectionqualification.' + props.item.qualification) }}
                          </div>
                          <div v-else>
                            {{ $t('emptyColumn') }}
                          </div>
                        </div>
                        <div v-else-if="header.value == 'customer'">
                          <div v-if="props.item.customer != null">
                            {{ props.item.customer.name }}
                          </div>
                          <div v-else>
                            {{ $t('emptyColumn') }}
                          </div>
                        </div>
                        <div v-else-if="header.value == 'treatedBy'">
                          <div v-if="props.item.treatedBy != null">
                            <div>
                              {{
                                $t('personNameMinified', {
                                  first: props.item.treatedBy.firstName,
                                  last: props.item.treatedBy.lastName[0]
                                })
                              }}
                            </div>
                          </div>
                          <div v-else>
                            {{ $t('emptyColumn') }}
                          </div>
                        </div>
                        <div v-else-if="header.value == 'consolidated'">
                          <div v-if="props.item.consolidated != null">
                            <v-icon
                                v-if="props.item.consolidated"
                                class="green--text text--darken-3"
                                small
                            >
                              mdi-check-decagram
                            </v-icon>
                            <v-icon
                                v-else
                                small
                            >
                              mdi-decagram
                            </v-icon>
                          </div>
                          <div v-else>
                            {{ $t('emptyColumn') }}
                          </div>
                        </div>
                        <div v-else>
                          {{ $t('emptyColumn') }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <div v-intersect="onIntersect"/>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-navigation-drawer
              v-model="drawerFilter"
              fixed
              right
              temporary
              hide-overlay
              class="filter-nav-drawer"
          >
            <v-flex class="filter-box pxc-6">
              <v-card
                  dark
                  pa-5
                  width="300"
                  class="menu-card"
                  flat
              >
                <v-card-title>
                  <v-icon class="mr-2">
                    mdi-filter-variant
                  </v-icon>
                  <span class="card-title">{{
                      $t('dashboard.filters.title')
                    }}</span>
                </v-card-title>
                <v-divider/>
                <v-card-subtitle>
                  {{ $t('dashboard.filters.subtitle') }}
                </v-card-subtitle>
                <v-card-text>
                  <v-card-subtitle>
                    {{ $t('dashboard.filters.byStatus') }}
                  </v-card-subtitle>
                  <v-container
                      v-for="(key, val) in STATUS_ENUM"
                      :key="'stkey'+key"
                      class="px-0 pt-1 pb-1"
                      fluid
                  >
                    <v-chip
                        v-if="val !== 'NONE'"
                        class="chip-filter"
                        small
                        filter
                        :input-value="filters.statusFilter.includes(val)"
                        :disabled="dataLoader"
                        @click="changeStatusFilter(val)"
                    >
                      <span>
                        {{ $t('constant.rejectionstatus.' + val) }}
                      </span>
                    </v-chip>
                  </v-container>
                  <v-card-subtitle>
                    {{ $t('dashboard.filters.byQualification') }}
                  </v-card-subtitle>
                  <v-container
                      v-for="val in QUALIFICATION"
                      :key="'qualkey'+val"
                      class="px-0 pt-1 pb-1"
                      fluid
                  >
                    <v-chip
                        class="chip-filter"
                        small
                        filter
                        :input-value="filters.qualificationFilter.includes(val)"
                        :disabled="dataLoader"
                        @click="changeQualificationFilter(val)"
                    >
                      <span>
                        {{ $t('constant.rejectionqualification.' + val) }}
                      </span>
                    </v-chip>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-navigation-drawer>
        </v-layout>
      </v-container>
      <CreateReject
          v-if="dialogCreateReject"
          @close="dialogCreateReject = false"
      />
    </v-container>
  </v-tab-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FieldStates from '@/components/general/fields/FieldStates'
import fieldHandler from '@/mixins/fieldHandler'
import { INPUT_STATES, QUALIFICATION, STATUS_COLOR, STATUS_ENUM } from '@/plugins/constants'
import RejectAdvanceSearch from '@/components/dashboard/advanceSearch/RejectAdvanceSearch'
import rejectService from '@/services/reject/rejectService'
import CreateReject from '@/components/dashboard/createReject/CreateReject'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'Dashboard',
  components: {
    FieldStates,
    CreateReject,
    RejectAdvanceSearch
  },
  mixins: [fieldHandler],
  data: () => ({
    dialogCreateReject: false,
    drawerFilter: false,
    state: INPUT_STATES.NONE,
    filters: {
      search: '',
      statusFilter: [],
      qualificationFilter: []
    },
    advanceFilters: {
      searchCustomers: [],
      searchTreatedBy: [],
      searchTags: [],
      searchCreateDateFrom: '',
      searchCreateDateTo: ''
    },
    message: '',
    dataTableOptions: {},
    dataTableSortBy: [],
    dataTableSortDesc: '',
    rejects: [],
    rejectTypesList: [],
    pageToLoad: 0,
    itemsTotalNumber: 50,
    itemsLoadedNumber: 0,
    dataLoader: false,
    isInit: false,
    hideClosed: true,
    STATUS_COLOR: STATUS_COLOR,
    STATUS_ENUM: STATUS_ENUM,
    QUALIFICATION: QUALIFICATION,
    totalElements: 0
  }),
  computed: {
    ...mapGetters({
      canCreateReject: 'privileges/reject/canCreateReject',
      canSeeRejectsList: 'privileges/reject/canSeeRejectsList'
    }),
    noFilters () {
      if (
        this.filters.search != '' ||
          this.filters.statusFilter.length > 0 ||
          this.filters.qualificationFilter.length > 0 ||
          this.advanceFilters.searchCustomers.length > 0 ||
          this.advanceFilters.searchTreatedBy.length > 0 ||
          this.advanceFilters.searchTags.length > 0 ||
          this.advanceFilters.searchCreateDateFrom !== '' ||
          this.advanceFilters.searchCreateDateTo !== ''
      ) {
        return false
      }
      return true
    },
    tableChips () {
      const result = []
      if (this.filters.search != '') {
        result.push({
          name: this.$t('dashboard.filters.search') + this.filters.search,
          key: 'search'
        })
      }
      for (const cust of this.advanceFilters.searchCustomers) {
        result.push({ name: this.$t('dashboard.filters.customer') + cust.name, key: 'customer' + '-' + cust.id })
      }
      for (const tby of this.advanceFilters.searchTreatedBy) {
        result.push({ name: this.$t('dashboard.filters.treatedBy') + tby.firstName + ' ' + tby.lastName[0] + '.', key: 'treatedBy' + '-' + tby.id })
      }
      for (const tag of this.advanceFilters.searchTags) {
        result.push({ name: this.$t('dashboard.filters.tag') + tag.name, key: 'tag' + '-' + tag.id })
      }
      if (this.advanceFilters.searchCreateDateFrom != '') {
        result.push({ name: this.$t('dashboard.filters.from') + this.advanceFilters.searchCreateDateFrom, key: 'createDateFrom' })
      }
      if (this.advanceFilters.searchCreateDateTo != '') {
        result.push({ name: this.$t('dashboard.filters.to') + this.advanceFilters.searchCreateDateTo, key: 'createDateTo' })
      }
      for (const filter of this.filters.statusFilter) {
        result.push({ name: this.$t('constant.rejectionstatus.' + filter), key: 'status' + filter })
      }
      for (const filter of this.filters.qualificationFilter) {
        result.push({ name: this.$t('constant.rejectionqualification.' + filter), key: 'qualification' + filter })
      }
      return result
    },
    headers () {
      return [
        { text: this.$t('dashboard.dataTable.headers.invoice'), value: 'originInvoice', width: 156, align: 'center', class: 'dashboard-th' },
        { text: this.$t('dashboard.dataTable.headers.status'), value: 'status', width: 170, align: 'center', class: 'dashboard-th' },
        { text: this.$t('dashboard.dataTable.headers.created'), value: 'createDate', align: 'center', class: 'dashboard-th' },
        { text: this.$t('dashboard.dataTable.headers.qualification'), value: 'qualification', align: 'center', class: 'dashboard-th' },
        { text: this.$t('dashboard.dataTable.headers.customer'), value: 'customer', align: 'center', class: 'dashboard-th' },
        { text: this.$t('dashboard.dataTable.headers.tags'), value: 'tags', align: 'center', class: 'dashboard-th' },
        { text: this.$t('dashboard.dataTable.headers.treatedBy'), value: 'treatedBy', align: 'center', class: 'dashboard-th' },
        { text: this.$t('dashboard.dataTable.headers.consolidated'), value: 'consolidated', align: 'center', class: 'dashboard-th' }
      ]
    },
    tabId () {
      return this.$router.resolve({ name: 'dashboard' }).href
    }
  },
  watch: {
    'filters.search': {
      handler () {
        this.loadData(0)
      },
      deep: true
    },
    dataTableOptions: {
      handler () {
        this.changeOptionTable()
      },
      deep: true
    }
  },
  created () {
    this.$rt.subscribe('/rejections/all', this.onRealTimeUpdated)
    this.getAllRejects = _.debounce(this.getAllRejects, 500)
    this.resetState = _.debounce(this.resetState, 10000)
    this.updateTab({ tabId: this.tabId, name: this.$t('dashboard.name') })
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.$rt.unsubscribe('/rejections/all', this.onRealTimeUpdated)
  },
  methods: {
    ...mapActions({
      updateTab: 'updateTab'
    }),
    init () {
      this.loadData(0)
    },
    closeTableChip (tableChip) {
      if (tableChip.key.startsWith('customer')) {
        this.advanceFilters.searchCustomers = this.advanceFilters.searchCustomers.filter(cust => cust.id != tableChip.key.split('-')[1])
        this.loadData(0)
      }
      if (tableChip.key.startsWith('treatedBy')) {
        this.advanceFilters.searchTreatedBy = this.advanceFilters.searchTreatedBy.filter(tby => tby.id != tableChip.key.split('-')[1])
        this.loadData(0)
      }
      if (tableChip.key.startsWith('tag')) {
        this.advanceFilters.searchTags = this.advanceFilters.searchTags.filter(tag => tag.id != tableChip.key.split('-')[1])
        this.loadData(0)
      }
      if (tableChip.key.startsWith('createDateFrom')) {
        this.advanceFilters.searchCreateDateFrom = ''
        this.loadData(0)
      }
      if (tableChip.key.startsWith('createDateTo')) {
        this.advanceFilters.searchCreateDateTo = ''
        this.loadData(0)
      }
      if (tableChip.key == 'search') {
        this.filters.search = ''
      }
      if (tableChip.key.startsWith('status')) {
        this.changeStatusFilter(tableChip.key.replace('status', ''))
      }
      if (tableChip.key.startsWith('qualification')) {
        this.changeQualificationFilter(tableChip.key.replace('qualification', ''))
      }
    },
    loadData (pageToLoad) {
      this.pageToLoad = pageToLoad
      if (this.pageToLoad == 0) {
        this.itemsLoadedNumber = 0
        this.itemsTotalNumber = 50
      }
      if (this.itemsLoadedNumber < this.itemsTotalNumber) {
        this.dataLoader = true
        this.getAllRejects()
      }
    },
    updateHideClosed () {
      this.loadData(0)
    },
    getAllRejects () {
      if (this.canSeeRejectsList) {
        this.message = ''
        this.state = INPUT_STATES.NONE
        this.isInit = false
        const jsonAdvanceSearch = {
          createDateFrom: this.advanceFilters.searchCreateDateFrom,
          createDateTo: this.advanceFilters.searchCreateDateTo,
          customers: this.advanceFilters.searchCustomers ? this.advanceFilters.searchCustomers.map(obj => obj.id) : [],
          treatedBy: this.advanceFilters.searchTreatedBy ? this.advanceFilters.searchTreatedBy.map(obj => obj.id) : [],
          tags: this.advanceFilters.searchTags ? this.advanceFilters.searchTags.map(obj => obj.id) : []
        }
        let statusFilter = _.cloneDeep(this.filters.statusFilter)
        console.log('filters statusFilter', this.filters.statusFilter)
        if (this.hideClosed) {
          if (_.isEmpty(statusFilter)) {
            statusFilter = [STATUS_ENUM.NEW, STATUS_ENUM.IN_PROGRESS, STATUS_ENUM.TREATED]
          } else {
            _.remove(statusFilter, function (n) {
              return n === STATUS_ENUM.CLOSED
            })
            if (_.isEmpty(statusFilter)) {
              statusFilter = [STATUS_ENUM.NONE]
            }
          }
          console.log('hideClosed statusFilter', statusFilter)
        }
        rejectService.getAllRejects(this.pageToLoad, this.dataTableSortBy, this.dataTableSortDesc, this.filters.search, statusFilter, this.filters.qualificationFilter, jsonAdvanceSearch).then(response => {
          this.popuplateTable(response)
        }).catch(error => {
          this.state = INPUT_STATES.ERROR
          this.message = this.$t('dashboard.loadingError')
          console.log('Error : retour du service getAllRejects : ' + error)
        }).finally(() => {
          this.dataLoader = false
          this.isInit = true
        })
      }
    },
    popuplateTable (values) {
      const itemsPerPage = 50
      if (this.pageToLoad == 0) {
        this.itemsLoadedNumber = values.numberOfElements
          ? values.numberOfElements
          : values.length
        this.rejects = values.content ? values.content : values
      } else {
        this.itemsLoadedNumber += values.numberOfElements
          ? values.numberOfElements
          : itemsPerPage
        this.rejects = this.rejects.concat(
          values.content ? values.content : values
        )
      }
      this.itemsTotalNumber = values.totalElements
        ? values.totalElements
        : values.length
      this.dataTableOptions.itemsPerPage = this.rejects.length
      this.pageToLoad++
    },
    setClear () {
      this.advanceFilters.searchCustomers = []
      this.advanceFilters.searchTreatedBy = []
      this.advanceFilters.searchTags = []
      this.advanceFilters.searchCreateDateFrom = ''
      this.advanceFilters.searchCreateDateTo = ''
      this.loadData(0)
    },
    setResearch (newCustomers, newUsers, newTags, newCreateDateFrom, newCreateDateTo) {
      this.advanceFilters.searchCustomers = newCustomers
      this.advanceFilters.searchTreatedBy = newUsers
      this.advanceFilters.searchTags = newTags
      this.advanceFilters.searchCreateDateFrom = newCreateDateFrom
      this.advanceFilters.searchCreateDateTo = newCreateDateTo
      this.loadData(0)
    },
    changeStatusFilter (item) {
      let result = false
      let i = 0
      for (i; i < this.filters.statusFilter.length; i++) {
        if (item == this.filters.statusFilter[i]) {
          result = true
          break
        }
      }
      if (result) this.filters.statusFilter.splice(i, 1)
      else this.filters.statusFilter.push(item)
      this.loadData(0)
    },
    changeQualificationFilter (item) {
      let result = false
      let i = 0
      for (i; i < this.filters.qualificationFilter.length; i++) {
        if (item == this.filters.qualificationFilter[i]) {
          result = true
          break
        }
      }
      if (result) this.filters.qualificationFilter.splice(i, 1)
      else this.filters.qualificationFilter.push(item)
      this.loadData(0)
    },
    disableCustomSort () {
      return this.rejects
    },
    changeOptionTable () {
      const { sortBy, sortDesc } = this.dataTableOptions
      if (this.isInit && !_.isEqual(sortBy, this.dataTableSortBy)) {
        this.dataTableSortBy = sortBy
        this.dataTableSortDesc = sortDesc
        this.loadData(0)
      }
    },
    onIntersect (entries) {
      if (this.isInit && entries[0].isIntersecting) {
        this.loadData(this.pageToLoad)
      }
    },
    formatDate (date) {
      return date ? moment(date).format('DD MMMM YYYY') : this.$t('general.emptyColumn')
    },
    formatHour (date) {
      return date ? moment(date).format('HH') + 'h' + moment(date).format('mm') : this.$t('general.emptyColumn')
    },
    onRealTimeUpdated (update) {
      const response = JSON.parse(update)
      console.log('Dashboard onRealTimeUpdated : ', response)
      const index = _.findIndex(this.rejects, { id: response.elementId })
      if (index >= 0) {
        if (_.has(response.fields, 'treatedBy')) {
          this.rejects[index].treatedBy = response.fields.treatedBy
        }
        if (_.has(response.fields, 'status')) {
          this.rejects[index].status = response.fields.status
        }
        if (_.has(response.fields, 'qualification')) {
          this.rejects[index].qualification = response.fields.qualification
        }
        if (_.has(response.fields, 'consolidated')) {
          this.rejects[index].consolidated = response.fields.consolidated
        }
        if (_.has(response.fields, 'tags')) {
          this.rejects[index].tags = response.fields.tags
        }
        this.rejects.splice(index, 1, this.rejects[index])
        this.resetState()
      } else {
        if (_.has(response.fields, 'rejects')) {
          this.onRealTimeUpdate(this, 'rejects', 'rejects', update)
          this.popuplateTable(this.rejects)
          this.clearFilters()
        }
      }
    },
    resetState () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    },
    openRejectDetailsTab (id) {
      // if (this.canSeeReject) {
      this.$router.push({
        name: 'reject-card',
        params: {
          id: id,
          tabIndex: '1'
        }
      }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
      // }
    },
    clearFilters () {
      this.tableChips.forEach(el => {
        this.closeTableChip(el)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.filter-box {
  width: 300px;
}

.data-box {
  width: calc(100% - 300px);
  margin-top: -12px;
}

.fieldStates {
  padding-bottom: 2px;
}

.v-data-table__wrapper tr:hover {
  cursor: pointer;
  background: $tr-hover;
}

td,
.v-data-table th {
  font-size: 0.7rem !important;
  padding: 0 8px !important;
  min-width: 97px;
  max-width: 100px;
}

.menu-card {
  background: $primary !important;
}

.menu-card > .v-card__title {
  color: white !important;
}

.menu-card > .v-divider {
  border-color: $title !important;
}

.chip-filter {
  font-size: 0.7rem;
  background: transparent !important;
  border: solid 1px $item-icon !important;
}

.badge-number {
  margin-left: 8px;
  margin-right: -11px;
  padding: 0 5px;
  text-align: center;
  min-width: 20px;
  min-height: 20px;
  border-radius: 12px !important;
  background-color: $item-icon;
}

.flag-icon {
  color: $item-icon !important;
}

.flag-icon:hover {
  color: $pink !important;
  cursor: pointer;
}

.flag-icon-active {
  color: $pink !important;
}

.cellContainer {
  text-align: center;
}

.filter-nav-drawer {
  margin-top: 168px;
  background-color: #eeeeee;
}

.text-capitalize {
  text-transform: capitalize;
}

.statuschip {
  width: 100px;
  text-align: center;
  justify-content: center;
}

.tagchip {
  max-width: 100px;
  transition: max-width 0.3s ease-in-out;
}

.tagchip:hover {
  max-width: 300px;
}

.strong-class {
  font-weight: bold;
}

</style>
