<template>
  <v-container
      v-if="connected"
      fluid
      pa-0
      fill-height
  >
    <v-container
        v-if="isInit"
        class="white-background tab-wrapper"
        fluid
        pa-0
        fill-height
    >
      <div class="logo-header d-flex justify-space-between align-center">
        <v-row>
          <v-col cols="12">
            <v-row
                justify="center"
            >
              <div class="d-flex justify-center align-center pr-2 text-center">
                <span class="text-pre-wrap custom-font-ogre mb-4">
                  {{ $t('ogre') }}
                </span>
                <span class="custom-font-by mb-2">
                   {{ $t('by') }}
                </span>
              </div>
              <v-img
                  src="@/assets/logo_bt.png"
                  max-width="250"
              />
              <v-divider
                  v-if="me.logoUrl"
                  vertical
                  class="divider-header"
              />
              <v-img
                  v-if="me.logoUrl"
                  :src="getPhotoUrl(me.logoUrl)"
                  max-width="250"
              />
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-layout
          wrap
          class="content-wrapper"
      >
        <v-toolbar
            dense
            absolute
            flat
            height="44"
        >
          <v-tabs
              v-model="currentTab"
              class="tabs-line"
              height="44"
              hide-slider
              show-arrows
          >
            <draggable
                class="v-slide-group__content v-tabs-bar__content"
                :options="{ group: 'tabs-group' }"
            >
              <v-tab
                  v-for="(item, index) in tabsList"
                  :key="item.id"
                  ripple
                  color="red"
                  :class="item.closeable && index == currentTab ? 'close content-toolbar' : 'content-toolbar'"
              >
                <span>{{ item.name }}</span>
                <v-btn
                    v-if="item.closeable && index == currentTab"
                    dark
                    x-small
                    fab
                    icon
                    pr-0
                    @click.stop="closeTab(item.id)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-tab>
            </draggable>
          </v-tabs>
          <v-spacer/>
          <v-btn
              v-if="canSeeStatistics"
              icon
              small
              :class="{ 'content-toolbar': true, 'mx-0': canSeeStatistics }"
              @click.stop="openStatisticsTab()"
          >
            <v-icon>mdi-chart-bar-stacked</v-icon>
          </v-btn>
          <Notifications/>
          <v-btn
              v-if="canSeeAdministration"
              icon
              small
              class="mx-3 content-toolbar"
              @click.stop="openAdminTab()"
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
          <v-menu
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
              class="menu-toolbar"
          >
            <template #activator="{ on }">
              <v-avatar
                  color="#008FB6"
                  size="30"
                  class="ml-2 content-toolbar"
                  v-on="on"
              >
                <span class="white--text">{{ userInitials }}</span>
              </v-avatar>
            </template>
            <v-card
                max-width="344"
                class="mx-auto"
            >
              <v-list-item>
                <v-list-item-avatar color="#008FB6">
                  <span class="white--text">{{ userInitials }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ userName }}</v-list-item-title>
                  <v-list-item-subtitle>#{{ me.id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="subscribeLoad"/>
              <v-row v-if="subscribeLoad">
                <v-col>
                  <div class="mx-5">
                    <v-switch
                        v-model="isSubscribeByEmail"
                        :label="$t('notifications.byemail')"
                        @click="updateSubscribeByEmail"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-divider/>
              <v-card-actions>
                <div class="flex-grow-1"/>
                <v-btn
                    text
                    @click.stop="$router.push({ name: 'logout' })"
                >
                  {{ $t('logout') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>
        <v-tabs-items
            v-model="currentTab"
            class="transparent"
        >
          <component
              :is="item.component"
              v-for="item in tabsList"
              :key="item.id"
              :value="item.value"
              :tab-index="item.tabIndex"
          />
        </v-tabs-items>
      </v-layout>
    </v-container>
    <v-dialog
        v-model="logoutAlert"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title>{{ $t('miscellaneous.disconnect.title') }}</v-card-title>
        <v-card-text>{{ $t('miscellaneous.disconnect.text') }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="red darken-1"
              text
              @click="redirectLogin"
          >
            {{ $t('miscellaneous.disconnect.button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import NavigationDrawer from '@/components/general/navigationDrawer/NavigationDrawer'
import Dashboard from '@/components/dashboard/Dashboard'
import RejectCard from '@/components/reject/RejectCard'
import Administration from '@/components/administration/Administration'
import Statistics from '@/components/statistics/Statistics'
import meService from '@/services/me/meService'
import _ from 'lodash'
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'
import photoFunction from '@/mixins/photoFunction'
import initGlobalSubscriptions from './initGlobalSubscriptions'
import Notifications from '@/components/miscellaneous/Notifications'
import subscriptionService from '@/services/notification/subscriptionService'

export default {
  name: 'Home',
  components: {
    Notifications,
    NavigationDrawer,
    Dashboard,
    draggable,
    RejectCard,
    Administration,
    Statistics
  },
  mixins: [photoFunction],
  data: () => ({
    urlToOpen: [],
    baseURL: '',
    connected: false,
    isInit: false,
    logoutAlert: false,
    isSubscribeByEmail: false,
    subscribeLoad: false
  }),
  computed: {
    ...mapGetters({
      me: 'user',
      tabsList: 'tabs',
      userName: 'userName',
      userInitials: 'userInitials',
      getCurrentTab: 'currentTab',
      canSeeAdministration: 'privileges/administration/canSeeAdministration',
      canSeeStatistics: 'privileges/administration/canSeeStatistics'
    }),
    currentTab: {
      get () {
        return this.getCurrentTab
      },
      set (newValue) {
        if (this.tabsList[newValue]) {
          this.$router.push(this.tabsList[newValue].id).catch(error => {
            if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
              console.error(error)
            }
          })
        }
      }
    }
  },
  created () {
    initGlobalSubscriptions.created(this.$rt)
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    initGlobalSubscriptions.beforeDestroy(this.$rt)
  },
  methods: {
    ...mapActions({
      clearMyData: 'clearMyData',
      setMyData: 'setMyData',
      setTabs: 'setTabs',
      openTab: 'openTab',
      deleteTab: 'closeTabById',
      setCurrentTab: 'setCurrentTab',
      initPrivileges: 'privileges/initPrivileges'
    }),
    async init () {
      this.baseURL = this.$router.resolve({ name: 'base' }).href

      // add the current url to the previously opened tabs before login redirection
      if (this.$router.currentRoute.path.startsWith(this.baseURL)) {
        this.addUrlToLocalStorage(this.$router.currentRoute.path)
      }

      // Init the tabs if connected
      if (await this.amIConnected()) {
        this.routeManager()
        this.setInitialTabs()
        // get the last current tab index in the localstorage before it gets updated
        const currentTabTemp = localStorage.currentTab
        // make sure that each tab will be opened only once
        for (const tab of _.uniqBy(this.urlToOpen, 'id')) {
          if (this.$route.path !== tab.id) {
            this.$router.push(tab.id).catch(error => {
              if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
                console.error(error)
              }
            })
          }
        }
        // set the current tab to the previous current tab if it's possible
        if (currentTabTemp <= this.tabsList.length - 1) {
          this.currentTab = currentTabTemp
        }

        console.log('AAAAAA', this.me)
        this.isSubscribeByEmail = this.me.subscribeByEmail
        this.subscribeLoad = true
        this.isInit = true
      } else {
        this.$router.push({ name: 'login' }).catch(error => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.error(error)
          }
        })
      }
    },
    setInitialTabs () {
      this.urlToOpen.push({ id: '/' })
      this.urlToOpen.push({ id: this.$router.resolve({ name: 'dashboard' }).href })
      // get the previously opened tabs in order to reopen them
      if (localStorage.openedTabs) {
        const openedTabs = JSON.parse(localStorage.openedTabs)
        if (openedTabs.length > 0) {
          for (const tab of openedTabs) {
            this.urlToOpen.push(tab)
          }
        }
      }
    },
    async amIConnected () {
      const result = await meService.getMyData().then(response => {
        this.setMyData(response)
        this.initPrivileges(response.privileges)
        // Initialize the websockets
        if (!this.$rt._isConnected()) {
          this.$rt.init(process.env.VUE_APP_BASE_WEBSOCKET_URL + response.settings.realTimeServerUrl, response.user.token)
          this.$bus.$on('RealTimeClient.logout', this.kickedOut)
          this.$bus.$on('SESSION.logout', this.kickedOut)
        }
        return true
      }).catch(() => {
        return false
      })
      this.connected = result
      return result
    },
    addUrlToLocalStorage (currentRoute) {
      let tabsLocalStorage = []
      if (localStorage.openedTabs) tabsLocalStorage = JSON.parse(localStorage.openedTabs)
      // search if the tab is already opened
      const index = _.findIndex(tabsLocalStorage, ['id', currentRoute])
      if (index >= 0) {
        localStorage.currentTab = index
      } else {
        tabsLocalStorage.push({ id: currentRoute })
        localStorage.openedTabs = JSON.stringify(tabsLocalStorage)
        localStorage.currentTab = tabsLocalStorage.length
      }
    },
    openAdminTab () {
      this.$router.push({ name: 'administration' }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    },
    openStatisticsTab () {
      this.$router.push({ name: 'statistics' }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    },
    closeTab (id) {
      this.deleteTab(id)
      this.$router.push(this.tabsList[this.currentTab].id).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    },
    buildTab (to) {
      const tab = {
        id: to.path,
        component: to.meta.component,
        name: '',
        closeable: to.meta.closeable != null ? to.meta.closeable : true
      }

      // get the tab name from previous open tabs
      if (!tab.name && _.find(this.urlToOpen, ['id', tab.id])) {
        tab.name = _.find(this.urlToOpen, ['id', tab.id]).name
      }

      if (to.params.id) tab.value = to.params.id
      if (to.params.tabIndex) tab.tabIndex = to.params.tabIndex
      return tab
    },
    routeManager () {
      this.$router.beforeEach((to, from, next) => this.beforeEach(to, from, next))
    },
    beforeEach (to, from, next) {
      if (to.path.startsWith(this.baseURL)) {
        if (to.meta.component) {
          const tab = this.buildTab(to)
          this.openTab(tab)
        }
      } else if (to.path == '/') {
        this.setCurrentTab(0)
      } else if (to.path == '/logout') {
        this.logout()
      }
      next()
    },
    logout () {
      meService.logout()
      this.$rt.logout()
      this.clearMyData()
      this.$router.push({ name: 'login' }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    },
    kickedOut () {
      meService.logout()
      this.$rt.logout()
      this.logoutAlert = true
    },
    redirectLogin () {
      this.logoutAlert = false
      this.$router.push({ name: 'login' }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    },
    updateSubscribeByEmail () {
      if (this.subscribeLoad) {
        if (this.isSubscribeByEmail) {
          subscriptionService.subscribeByEmail()
        } else {
          subscriptionService.unsubscribeByEmail()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.logo-header {
  position: absolute;
  top: 0;
  width: 100%;
}

.v-toolbar {
  width: 100%;
  margin-top: 79px;
  position: fixed;
  z-index: 3;
  border-top: 2px solid $border-bt !important;
  background-color: $surface-bt !important;

  .tabs-line {
    background-color: $surface-bt !important;
    margin-left: -20px !important;
    max-width: calc(100% - 140px);
  }

  .content-toolbar {
    margin-top: -4px;
  }
}

.tabs-line .v-tab:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.divider-header {
  margin: 7px;
  border-color: #292a2b !important;
}

.tab-wrapper {
  height: 100%;
  display: flex;
}

.tab-wrapper .v-window {
  flex: 1;
}

.tab-wrapper .v-window__container,
.tab-wrapper .v-window-item,
.v-tabs-items {
  height: 100%;
}

.tab-wrapper .card-content {
  height: 100%;
}

.v-toolbar__content > .v-tabs:first-child, .v-toolbar__extension > .v-tabs:first-child {
  margin-left: -10px;
}

.v-avatar {
  cursor: pointer;
  font-size: 0.9rem;
}

.v-menu__content {
  top: 85px !important;
}

.v-window-item {
  background: $background !important;
  position: absolute !important;
  top: 168px;
  height: calc(100% - 168px) !important;
  overflow: auto;
  width: 100%;
}

.white-background {
  background-color: white !important;
}

@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.custom-font-ogre {
  font-family: "Cantarell", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 28px;
}

.custom-font-by {
  font-family: "Unna", serif;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
}
</style>
