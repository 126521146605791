import _ from 'lodash'

const SET_CAN_SEE_ADMINISTRATION = 'SET_CAN_SEE_ADMINISTRATION'
const SET_CAN_SEE_STATISTICS = 'SET_CAN_SEE_STATISTICS'

const state = {
  canSeeAdministration: false,
  canSeeStatistics: false
}

const getters = {
  canSeeAdministration: (state) => state.canSeeAdministration,
  canSeeStatistics: (state) => state.canSeeStatistics
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanSeeAdministration', privileges)
    dispatch('setCanSeeStatistics', privileges)
  },
  setCanSeeAdministration ({ commit }, privileges) {
    commit(SET_CAN_SEE_ADMINISTRATION, !!_.find(privileges, ['code', 'VIEW_ADMIN']))
  },
  setCanSeeStatistics ({ commit }, privileges) {
    commit(SET_CAN_SEE_STATISTICS, !!_.find(privileges, ['code', 'VIEW_STATISTICS']))
  }
}

const mutations = {
  SET_CAN_SEE_ADMINISTRATION: (state, privilege) => {
    state.canSeeAdministration = privilege
  },
  SET_CAN_SEE_STATISTICS: (state, privilege) => {
    state.canSeeStatistics = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
