<template>
  <v-card elevation="0">
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{ $t('documents') }}</span>
      <FieldStates
          :state="state"
      />
      <transition name="fade">
              <span
                  v-if="message"
                  class="px-2 sub-label"
              >
                {{ message }}
              </span>
      </transition>
      <v-spacer/>
      <v-btn
          class="green-background"
          icon
          dark
          small
          :disabled="!canUpdateReject || readOnly"
          @click.stop="editDocument()"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
          class="green-background ml-1"
          icon
          dark
          small
          :disabled="!canUpdateReject || readOnly"
          @click="uploadDialog = true"
      >
        <v-icon>mdi-playlist-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6 pt-2">
      <v-data-table
          :headers="headersTab"
          :items="documents"
          :items-per-page="documents.length"
          :no-data-text="$t('reject.documents.dataTable.noData')"
          :expanded.sync="expanded"
          :sort-by="['name']"
          single-expand
          show-expand
          multi-sort
          hide-default-footer
      >
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <v-icon
              v-if="item.description"
              class="rotate"
              :class="isExpanded ? 'down': ''"
              @click="expand(!isExpanded)"
          >
            mdi-chevron-down
          </v-icon>
        </template>
        <template #expanded-item="{ headers, item }">
          <td
              v-if="item.description"
              :colspan="headers.length"
              class="py-3 px-5 desc-multi-line"
          >
            <div>{{ item.description }}</div>
          </td>
        </template>
        <template #[`item.name`]="{ item }">
                <span class="d-flex align-center">
                  <FileIcons
                      :value="value"
                      size="sm"
                      :file-url="item.url"
                  />
                  <span class="pl-3">
                    {{ item.name }}
                  </span>
                </span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
              class="mr-2"
              x-small
              icon
              :disabled="!(!!item.url)"
              :href="urlView(item)"
              target="_blank"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn
              class="mr-2"
              x-small
              icon
              :disabled="!(!!item.url)"
              :href="urlDownload(item)"
              target="_blank"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn
              class="mr-2"
              x-small
              icon
              :disabled="!canUpdateReject || readOnly"
              @click.stop="editDocument(item)"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
              icon
              x-small
              :disabled="!canUpdateReject || readOnly"
              @click.stop="confirmDeleting(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <AddDocument
        v-if="dialogEditDocument"
        :value="value"
        :item-to-edit="itemToEdit"
        @close="dialogEditDocument = false"
    />
    <ConfirmDialog ref="confirm"/>
    <DragDropUpload
        :dialog.sync="uploadDialog"
        :multiple="true"
        :sentence="$t('miscellaneous.dragDropUpload.sentence')"
        @filesUploaded="processUpload($event)"
    />
  </v-card>
</template>

<script>
import rejectService from '@/services/reject/rejectService'
import AddDocument from '@/components/reject/documents/AddDocument'
import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog'
import FieldStates from '@/components/general/fields/FieldStates'
import { INPUT_STATES } from '@/plugins/constants'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import DragDropUpload from '@/components/miscellaneous/DragDropUpload'
import FileIcons from '@/components/general/fileIcons/FileIcons'
import omitFunctions from '@/mixins/omitFunctions'

const COMPONENT_GRAPH = [
  'documents'
]

export default {
  name: 'RejectDocuments',
  components: {
    AddDocument,
    ConfirmDialog,
    DragDropUpload,
    FileIcons,
    FieldStates
  },
  mixins: [omitFunctions],
  props: ['value', 'readOnly'],
  data: () => ({
    state: INPUT_STATES.NONE,
    message: '',
    documents: [],
    dialogEditDocument: false,
    itemToEdit: {},
    expanded: [],
    updatedFlag: false,
    uploadDialog: false,
    canUpload: true,
    uploadedFiles: []
  }),
  computed: {
    ...mapGetters({
      me: 'user',
      canUpdateReject: 'privileges/reject/canUpdateReject'
    }),
    headersTab () {
      return [
        {
          text: this.$t('reject.documents.dataTable.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('reject.documents.dataTable.headers.actions'),
          value: 'actions',
          sortable: false,
          class: 'actionsColumn',
          width: 140
        },
        {
          text: '',
          value: 'data-table-expand'
        }
      ]
    }
  },
  created () {
    this.$rt.subscribe('/rejections/' + this.value, this.onRealTimeUpdated)
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.$rt.unsubscribe('/rejections/' + this.value, this.onRealTimeUpdated)
  },
  methods: {
    init () {
      rejectService.getReject(this.value, COMPONENT_GRAPH).then(response => {
        this.documents = response.documents || []
        this.sendDocuments()
      }).catch((error) => {
        console.log('RejectDocuments : Erreur initialisation : ' + error)
      })
    },
    editDocument (item) {
      this.itemToEdit = item ? _.cloneDeep(item) : {}
      this.dialogEditDocument = true
    },
    confirmDeleting (item) {
      this.$refs.confirm.open(this.$t('reject.documents.dataTable.deleteConfirmTitle'), this.$t('reject.documents.dataTable.deleteConfirmMessage', { message: item.name })
      ).then((confirm) => {
        if (confirm) this.deleteDocument(item)
      })
    },
    deleteDocument (item) {
      this.state = INPUT_STATES.LOADING
      this.updatedFlag = true
      rejectService.deleteDocument(this.value, item).then(response => {
        this.state = INPUT_STATES.SAVED
        this.message = ''
        this.resetState()
      }).catch((error) => {
        console.log('RejectDocuments : Erreur deleteDocument : ' + error)
        this.updatedFlag = false
        this.state = INPUT_STATES.ERROR
        this.message = this.$t('reject.documents.dataTable.deleteError')
      })
    },
    /*
    onDrop (e) {
      this.dragover = false

      // If there are already uploaded files remove them
      if (this.uploadedFiles.length > 0) this.uploadedFiles = []

      // Add each file to the array of uploaded files
      e.dataTransfer.files.forEach(element => this.uploadedFiles.push(element))

      this.processUpload(this.uploadedFiles)
    },
     */
    processUpload (files) {
      for (const f in files) {
        // console.log('file : ', files[f])
        const editedItem = {
          name: files[f].name
        }
        // console.log('editedItem : ', editedItem)
        this.uploadDocument(editedItem, files[f])
      }
    },
    uploadDocument (editedItem, file) {
      if (this.canUpload) {
        this.canUpload = false
        // console.log('uploadDocument createDocument : ', editedItem)
        rejectService.createDocument(this.value, editedItem, file).then(response => {
          this.canUpload = true
          this.state = INPUT_STATES.SAVED
        }).catch((error) => {
          this.canUpload = true
          console.log('Document : Erreur create : ' + error)
          this.state = INPUT_STATES.ERROR
          this.message = this.$t('reject.documents.addDocument.saveError')
        })
      } else {
        // console.log('uploadDocument createDocument : wait')
        setTimeout(() => {
          this.uploadDocument(editedItem, file)
        }, 100)
      }
    },
    urlView (item) {
      return '/api/rejections/' + this.value + '/documents/' + item.id + '/view'
    },
    urlDownload (item) {
      return '/api/rejections/' + this.value + '/documents/' + item.id + '/download'
    },
    onRealTimeUpdated (update) {
      const response = JSON.parse(update)
      if (_.has(response.fields, 'documents')) {
        this.init()
        this.sendDocuments()
        if (response.lastUpdater.id != this.me.id) {
          this.state = INPUT_STATES.UPDATED
          this.message = this.$t('message.updateBy', {
            first: response.lastUpdater.firstName,
            last: response.lastUpdater.lastName
          })
        } else if (!_.isEqual(response.fields.documents, this.documents) && !this.updatedFlag) {
          this.state = INPUT_STATES.SAVED
          this.message = ''
        }
        this.updatedFlag = false
        this.resetState()
      }
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    }, 10000),
    sendDocuments () {
      this.$emit('documentData', this.documents)
    }
  }
}
</script>

<style scoped>
.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.desc-multi-line {
  white-space: pre-line;
}

.dt-hidden {
  opacity: 0;
}

.dt-visible {
  width: 750px;
  text-align: center;
  position: absolute;
  top: 60px;
  left: calc(50% - 375px);
}

.f-size-14 {
  font-size: 14px;
}

</style>
