<template>
  <v-tab-item
      v-if="canSeeStatistics"
      :transition="false"
      :reverse-transition="false"
  >
    <v-container
        fluid
        pa-0
    >
      <v-layout
          row
          class="pyc-6 pxc-6"
      >
        <v-toolbar
            elevation="0"
            dense
            absolute
            height="44"
            class="under-toolbar"
        >
          <v-toolbar-title class="under-toolbar-title">
            {{ $t('statistics.general.toolbarTitle') }}
          </v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-container
            fluid
            grid-list-xl
            px-3
            py-2
        >
          <v-card
              flat
              :loading="statusDataLoader"
          >
            <v-card-title class="pl-5">
              <span class="mr-3 card-title">{{ $t('statistics.general.export.titleStatus') }}</span>
              <v-spacer/>
              <v-btn
                  v-if="canSeeAdministration"
                  icon
                  small
                  @click="downloadStatusStats()"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <v-row class="d-flex align-center">
                <v-spacer/>
                <v-col sm="auto">
                  <span>
                    {{ $t("statistics.general.filters") }}
                  </span>
                </v-col>
                <v-col sm="2">
                  <v-menu
                      v-model="menuStatusData"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      range
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                          v-model="dateRangeTextStatusData"
                          :label="$t('statistics.general.export.dates')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="datesStatusData"
                        range
                        @change="menuStatusData = false; getStatusData()"
                    />
                  </v-menu>
                </v-col>
                <v-col sm="4">
                  <v-combobox
                      v-model="searchStatusData"
                      class="mt-2"
                      :items="statStatus"
                      item-text="customerName"
                      :label="$t('statistics.dataTable.client')"
                      clearable
                      multiple
                      small-chips
                      deletable-chips
                  >
                    <template #selection="{ item, index }">
                      <v-chip
                          v-if="index === 0"
                          x-small
                      >
                        <span>{{ item.customerName }}</span>
                      </v-chip>
                      <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                      >
                        {{ $tc('statistics.general.others', searchStatusData.length - 1, { number: searchStatusData.length - 1 }) }}
                      </span>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-data-table
                  :headers="headersStatus"
                  :items="filteredStatStatus"
                  :items-per-page="statStatus.length"
                  height="420px"
                  multi-sort
                  hide-default-footer
                  disable-pagination
                  fixed-header
              />
            </v-card-text>
          </v-card>
          <v-row>
            <v-col :sm="canSeeAdministration ? 7 : 12">
              <v-card
                  flat
                  :loading="tagsDataLoader"
                  class="mt-1"
              >
                <v-card-title class="pl-5">
                  <span class="mr-3 card-title">{{ $t('statistics.general.export.titleTags') }}</span>
                  <v-spacer/>
                  <v-tooltip
                      v-if="canSeeAdministration"
                      bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-bind="attrs"
                          class="mt-n2"
                          small
                          @click="downloadTagsStatsOneColumn()"
                          v-on="on"
                      >
                        <MultiIcons
                            icon-primary="mdi-file-tree"
                            icon-second="mdi-download"
                            position="bottom"
                            in-button="true"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t('statistics.general.export.tooltip.monoColumn') }}</span>
                  </v-tooltip>
                  <v-tooltip
                      v-if="canSeeAdministration"
                      bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                          icon
                          small
                          class="mt-n2 ml-4"
                          v-bind="attrs"
                          @click="downloadTagsStatsMultiColumns()"
                          v-on="on"
                      >
                        <MultiIcons
                            icon-primary="mdi-reorder-vertical"
                            icon-second="mdi-download"
                            position="bottom"
                            in-button="true"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t('statistics.general.export.tooltip.multiColumn') }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                  <v-row class="d-flex align-center">
                    <v-spacer/>
                    <v-col sm="auto">
                      <span>
                        {{ $t("statistics.general.filters") }}
                      </span>
                    </v-col>
                    <v-col sm="3">
                      <v-menu
                          v-model="menuTagsData"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          range
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                              v-model="dateRangeTextTagsData"
                              :label="$t('statistics.general.export.dates')"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          />
                        </template>
                        <v-date-picker
                            v-model="datesTagsData"
                            range
                            @change="menuTagsData = false; getTagsData()"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                        sm="5"
                    >
                      <v-combobox
                          v-model="searchTagsData"
                          class="mt-2"
                          :items="statTags"
                          item-text="customer"
                          :label="$t('statistics.dataTable.client')"
                          clearable
                          multiple
                          small-chips
                          deletable-chips
                      >
                        <template #selection="{ item, index }">
                          <v-chip
                              v-if="index === 0"
                              x-small
                          >
                            <span>{{ item.customer }}</span>
                          </v-chip>
                          <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                          >
                            {{ $tc('statistics.general.others', searchTagsData.length - 1, { number: searchTagsData.length - 1 }) }}
                          </span>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-data-table
                      :expanded.sync="expanded"
                      :headers="headersTags"
                      :items="filteredStatTags"
                      :items-per-page="statTags.length"
                      item-key="customer"
                      hide-default-footer
                      disable-pagination
                      show-expand
                      disable-sort
                      height="371px"
                      fixed-header
                  >
                    <template #expanded-item="{ item, headers }">
                      <td :colspan="headers.length">
                        <div
                            v-for="(tag, index) in item.tags"
                            :key="index"
                        >
                          <v-list-item>
                            <v-row>
                              <v-spacer/>
                              <v-col sm="5">
                                <span>{{ tag.first }}</span>
                              </v-col>
                              <v-col
                                  sm="6"
                                  class="d-flex justify-center"
                              >
                                <span>{{ tag.second }}</span>
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="5">
              <v-card
                  v-if="canSeeAdministration"
                  elevation="0"
                  class="mt-1"
              >
                <v-card-title class="pl-5">
                  <span class="mr-3 card-title">{{ $t('statistics.general.export.title') }}</span>
                </v-card-title>
                <v-divider/>
                <v-card-text class="pa-6">
                  <v-row>
                    <v-col sm="7">
                      <v-date-picker
                          v-model="dates"
                          range
                      />
                    </v-col>
                    <v-spacer/>
                    <v-col sm="4">
                      <v-text-field
                          v-model="dateRangeTextExportData"
                          :label="$t('statistics.general.export.dates')"
                          prepend-icon="mdi-calendar"
                          readonly
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                        offset="6"
                        class="d-flex justify-end"
                        sm="6"
                    >
                      <v-btn
                          v-if="canSeeAdministration"
                          :disabled="dates.length != 2"
                          elevation="2"
                          :loading="loading"
                          @click="exportStats"
                      >
                        {{ $t('statistics.general.export.button') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
    </v-container>
  </v-tab-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import rejectService from '@/services/reject/rejectService'
import statisticsService from '@/services/statistics/statisticsService'
import MultiIcons from '@/components/miscellaneous/MultiIcons.vue'
import _ from 'lodash'

export default {
  name: 'Statistics',
  components: { MultiIcons },
  data: () => ({
    statusDataLoader: true,
    tagsDataLoader: true,
    component: '',
    currentTab: 0,
    dates: [],
    loading: false,
    statStatus: [],
    statTags: [],
    expanded: [],
    datesStatusData: [],
    menuStatusData: false,
    datesTagsData: [],
    menuTagsData: false,
    searchTagsData: [],
    searchStatusData: []
  }),
  computed: {
    ...mapGetters({
      canSeeStatistics: 'privileges/administration/canSeeStatistics',
      canSeeAdministration: 'privileges/administration/canSeeAdministration'
    }),
    tabId () {
      return this.$router.resolve({ name: 'statistics' }).href
    },
    dateRangeTextExportData () {
      return this.dates.map(obj => this.formatDate(obj)).join(' ~ ')
    },
    dateRangeTextStatusData () {
      return this.datesStatusData.length === 2 ? this.datesStatusData.map(obj => this.formatDate(obj)).join(' ~ ') : ''
    },
    dateRangeTextTagsData () {
      return this.datesTagsData.length === 2 ? this.datesTagsData.map(obj => this.formatDate(obj)).join(' ~ ') : ''
    },
    headersStatus () {
      return [
        { text: this.$t('statistics.dataTable.client'), value: 'customerName', align: 'center' },
        { text: this.$t('statistics.dataTable.new'), value: 'new', align: 'center' },
        { text: this.$t('statistics.dataTable.inProgress'), value: 'inProgress', align: 'center' },
        { text: this.$t('statistics.dataTable.treated'), value: 'treated', align: 'center' },
        { text: this.$t('statistics.dataTable.closed'), value: 'closed', align: 'center' },
        { text: this.$t('statistics.dataTable.total'), value: 'total', align: 'center' }
      ]
    },
    headersTags () {
      return [
        { text: this.$t('statistics.dataTable.client'), align: 'left', value: 'customer' },
        { text: this.$t('statistics.dataTable.numberTags'), align: 'center' },
        { text: '', value: 'data-table-expand' }
      ]
    },
    filteredStatStatus () {
      if (this.searchStatusData.length === 0) {
        return this.statStatus
      }
      const sortBy = _.sortBy(this.searchStatusData, ['customerName'])
      const elementTotal = sortBy.find(item => item.customerName === 'Total')
      const elements = sortBy.filter(item => item.customerName !== 'Total')
      if (elementTotal) {
        elements.push(elementTotal)
      }
      return elements
    },
    filteredStatTags () {
      if (this.searchTagsData.length === 0) {
        return this.statTags
      }
      return _.sortBy(this.searchTagsData, ['customer'])
    }
  },
  created () {
    this.updateTab({
      tabId: this.tabId,
      name: this.$t('statistics.tab.title')
    })
    this.checkPrivileges()
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions({
      updateTab: 'updateTab',
      closeTabById: 'closeTabById'
    }),
    init () {
      this.loadData()
    },
    loadData () {
      this.getStatusData()
      this.getTagsData()
    },
    getStatusData () {
      statisticsService.getStatusData(this.datesStatusData.length === 2 ? this.datesStatusData[0] : null, this.datesStatusData.length === 2 ? this.datesStatusData[1] : null).then(response => {
        this.statStatus = response
      }).catch(error => {
        console.log('Error : retour du service getStatusData : ' + error)
      }).finally(() => {
        this.statusDataLoader = false
      })
    },
    getTagsData () {
      statisticsService.getTagsData(this.datesTagsData.length === 2 ? this.datesTagsData[0] : null, this.datesTagsData.length === 2 ? this.datesTagsData[1] : null).then(response => {
        this.statTags = response
      }).catch(error => {
        console.log('Error : retour du service getStatusData : ' + error)
      }).finally(() => {
        this.tagsDataLoader = false
      })
    },
    checkPrivileges () {
      if (!this.canSeeStatistics) this.closeTabById(this.tabId)
    },
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : '-'
    },
    exportStats () {
      this.loading = true
      rejectService.getStatsXLSXFile(this.dates[0], this.dates[1]).then(response => {
        this.loading = false
      })
    },
    downloadStatusStats () {
      statisticsService.downloadStatusStats(this.datesStatusData.length === 2 ? this.datesStatusData[0] : null, this.datesStatusData.length === 2 ? this.datesStatusData[1] : null, this.customerListedStatus(this.filteredStatStatus))
    },
    downloadTagsStatsOneColumn () {
      statisticsService.downloadTagsStatsOneColumn(this.datesTagsData.length === 2 ? this.datesTagsData[0] : null, this.datesTagsData.length === 2 ? this.datesTagsData[1] : null, this.customerListedTags(this.filteredStatTags))
    },
    downloadTagsStatsMultiColumns () {
      statisticsService.downloadTagsStatsMultiColumns(this.datesTagsData.length === 2 ? this.datesTagsData[0] : null, this.datesTagsData.length === 2 ? this.datesTagsData[1] : null, this.customerListedTags(this.filteredStatTags))
    },
    customerListedStatus (array) {
      const listCustomer = []
      if (array.length === this.statStatus.length) {
        return listCustomer
      }
      for (const element in array) {
        listCustomer.push(array[element].customerName)
      }
      return listCustomer
    },
    customerListedTags (array) {
      const listCustomer = []
      if (array.length === this.statTags.length) {
        return listCustomer
      }
      for (const element in array) {
        listCustomer.push(array[element].customer)
      }
      return listCustomer
    }
  }
}
</script>

<style lang="scss">
th[aria-label="Actions"] {
  width: 130px;
}
</style>

<style lang="scss" scoped>
.tabs-line {
  width: 500px;
  max-width: 60%;
  height: 43px !important;
}

.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
