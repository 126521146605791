<template>
  <v-dialog
      :value="dialog"
      max-width="300"
      persistent
  >
    <v-card class="pa-5">
      <v-row
          class="d-flex flex-column py-4"
          align="center"
      >
        <span>{{ $t('loading') }}</span>
      </v-row>
      <v-row
          class="d-flex flex-column py-4"
          align="center"
      >
        <v-progress-circular
            rotate="270"
            color="#42A5F5"
            size="200"
            width="15"
            :value="calculatePercentage()"
        >
          <span>{{ fileSaved }} / {{ fileToSave }}</span>
        </v-progress-circular>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ProgressBarUploadFile',
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    fileSaved: {
      type: Number,
      default: 0
    },
    fileToSave: {
      type: Number,
      default: 0
    }
  },
  methods: {
    calculatePercentage () {
      if (this.fileToSave === 0) {
        return 100
      }
      return this.fileSaved / this.fileToSave * 100
    }
  }
}
</script>

<style scoped>

</style>
