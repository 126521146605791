import axios from 'axios'
import moment from 'moment'

export default {
  /**
   * getAllSuppliers : return all the suppliers
   * @param {Integer} pageToLoad - the page to load
   * @param {String} [sortBy] - the sort by option
   * @param {String} [sortDesc] - the sort order option
   * @param {String} [types] - filter by types separated by comma
   * @param {String} [country] - advanced filter by countries separated by comma
   * @param {String} [place] - advanced filter by places separated by comma
   * @param {String} [search] - search by reject name
   * @return {Array} the suppliers list
   */
  async getAllRejects (pageToLoad, sortBy, sortDesc, search, status, qualifications, jsonAdvanceSearch) {
    const { data } = await axios.get(`/rejections?page=${pageToLoad}&size${50}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${search}&status=${status}&qualifications=${qualifications}&customers=${jsonAdvanceSearch.customers}&treatedBy=${jsonAdvanceSearch.treatedBy}&tags=${jsonAdvanceSearch.tags}&createDateTo=${jsonAdvanceSearch.createDateTo}&createDateFrom=${jsonAdvanceSearch.createDateFrom}`)
    return data
  },

  async getReject (id, componentGraph) {
    let url = ''
    for (let i = 0; i < componentGraph.length; i++) {
      url += i == 0 ? componentGraph[i] : '+' + componentGraph[i]
    }
    const { data } = await axios.get('/rejections/' + id + '/graphs/' + url)
    return data
  },

  async updateReject (id, json) {
    const { data } = await axios.put('/rejections/' + id, json)
    return data
  },

  async closeReject (rejectId) {
    const { data } = await axios.patch(`/rejections/${rejectId}`)
    return data
  },

  async deleteReject (rejectId) {
    const { data } = await axios.delete(`/rejections/${rejectId}`)
    return data
  },

  async saveAction (rejectId, actionPayload) {
    const { data } = await axios.post(`/rejections/${rejectId}/actions`, actionPayload)
    return data
  },

  async updateAction (rejectId, editedAction) {
    const { data } = await axios.put(`/rejections/${rejectId}/actions/${editedAction.id}`, editedAction)
    return data
  },

  async completeAction (rejectId, actionId) {
    const { data } = await axios.post(`/rejections/${rejectId}/actions/${actionId}/complete`)
    return data
  },

  async deleteAction (rejectId, actionId) {
    const { data } = await axios.delete(`/rejections/${rejectId}/actions/${actionId}`)
    return data
  },

  async saveMessage (rejectId, messagePayload) {
    const { data } = await axios.post(`/rejections/${rejectId}/messages`, messagePayload)
    return data
  },

  async createTag (rejectId, tagName) {
    const { data } = await axios.post(`/rejections/${rejectId}/tags`, { name: tagName })
    return data
  },

  async sendResolve (rejectId, creditNote, invoice, resolutionMessage, documentsAttached) {
    const formData = new FormData()
    formData.append('creditNote', creditNote)
    formData.append('invoice', invoice)
    formData.append('resolutionMessage', resolutionMessage)
    for (const element of documentsAttached) {
      formData.append('documentsAttached', element.id)
    }
    const { data } = await axios.post(`/rejections/${rejectId}/resolved`, formData)
    return data
  },

  /**
   * createDocument : create a new document
   * @param {String} rejectionId - the rejection's id
   * @param {{name: *, type: string}} document - the document' attributes to create
   * @param {File} file - the file to upload
   * @return {Promise<*>}
   */
  async createDocument (rejectionId, document, file) {
    const formData = new FormData()
    formData.append('file', file)
    const stringifyDocument = JSON.stringify(document, (key, value) => {
      if (value == '') return undefined
      else return value
    })
    formData.append('payload', new Blob([
      stringifyDocument], {
      type: 'application/json'
    }))
    const response = await axios.post('/rejections/' + rejectionId + '/documents', formData)
    if (response.status !== 200) throw response
    return response
  },
  /**
   * updateDocument : update a new document
   * @param {String} rejectionId - the rejections's id
   * @param {Object} document - the document attributes to update
   * @param {File} file - the file to upload
   * @return {Promise<*>}
   */
  async updateDocument (rejectionId, document, file) {
    const formData = new FormData()
    formData.append('file', file)
    const jsonUpdate = { version: document.version, fields: document }
    const stringifyDocument = JSON.stringify(jsonUpdate, (key, value) => {
      if (value == '' || key == 'typeDisplay' || key == 'icon') return undefined
      else return value
    })
    formData.append('payload', new Blob([
      stringifyDocument], {
      type: 'application/json'
    }))
    const response = await axios.put('/rejections/' + rejectionId + '/documents/' + document.id, formData)
    if (response.status !== 200) throw response
    return response
  },

  /**
   * deleteDocument : delete a new document
   * @param {String} rejectionId - the rejection's id
   * @param {Object} document - the document' attributes to delete
   * @return {Promise<*>}
   */
  async deleteDocument (rejectionId, document) {
    const { data } = await axios.delete('/rejections/' + rejectionId + '/documents/' + document.id)
    return data
  },

  /**
   * getStatsXLSXFile : download stats xlsx
   */
  async getStatsXLSXFile (startDate, endDate) {
    const response = await axios.get(`/rejections/export/all?startDate=${startDate || ''}&endDate=${endDate || ''}`,
      {
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=utf-8'
        },
        responseType: 'blob'
      }
    )
    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)

    const dateNow = moment().format('DDMMYYYY-HHmmss')
    const dateStart = moment(startDate).format('DDMMYYYY')
    const dateEnd = moment(endDate).format('DDMMYYYY')

    link.setAttribute('download', 'rops_statistics_reports_' + dateNow + '_from_' + dateStart + '_to_' + dateEnd + '.xlsx')
    document.body.appendChild(link)
    link.click()
    return response
  },

  /**
   * getCascade : get the cascade of the reject
   * @param {Integer} rejectionId - the reject's id
   * @return {Promise<*>} the cascade list
   */
  async getCascade (rejectionId) {
    const { data } = await axios.get('/rejections/' + rejectionId + '/cascade/')
    return data
  }
}
