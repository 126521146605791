<template>
  <v-dialog
      v-model="dialog"
      max-width="700px"
      persistent
      scrollable
  >
    <v-card>
      <v-card-title class="pl-5">
        <span
            class="mr-3 card-title"
        >
          {{ $t('reject.resolve.title') }}
        </span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col
              v-if="reject.originInvoice[0] === '9' && reject.qualification == 'JUSTIFIED'"
              class="d-flex justify-end"
          >
            <span class="pt-5 pr-3 deep-orange--text lighten-2">{{ $t('reject.resolve.fields.doubleResolution') }}</span>
            <v-switch @change="doubleResolution = !doubleResolution"/>
          </v-col>
        </v-row>
        <form>
          <v-container>
            <v-row v-if="reject.qualification == 'JUSTIFIED'">
              <v-col
                  cols="12"
              >
                <span
                    class="multiline-text"
                    v-html="$t('reject.resolve.message.justified')"
                />
              </v-col>
            </v-row>
            <v-row v-if="reject.qualification == 'NOT_JUSTIFIED'">
              <v-col
                  cols="12"
              >
                <span
                    class="multiline-text"
                    v-html="$t('reject.resolve.message.notjustified')"
                />
              </v-col>
            </v-row>
            <v-row v-if="reject.qualification == 'NOT_JUSTIFIED'">
              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="resolutionMessage"
                    auto-grow
                    rows="1"
                >
                  <template #label>
                    {{ $t('reject.card.informations.rejectjustification') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-row>
                  <v-col cols="1">
                    <v-select
                        v-model="creditNoteRef"
                        :items="['A', 'F']"
                        @change="changeCreditNoteRef"
                    />
                  </v-col>
                  <v-col cols="11">
                    <v-text-field
                        v-model="creditNote"
                        counter="7"
                        type="number"
                        :error-messages="creditNoteErrors"
                        @blur="$v.creditNote.$touch()"
                        @input="$v.creditNote.$touch()"
                    >
                      <template #label>
                        {{ $t('reject.resolve.fields.creditNote') }}
                        <span class="red--text"><strong> *</strong></span>
                      </template>
                      <template #append-outer>
                        <span class="grey--text fact-inner-txt"> : {{ creditNoteRef }}-{{ addLeadingZeros(reject.customer.pointOfSale, 3) }} {{ computedZeroCreditNote }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="reject.qualification == 'NOT_JUSTIFIED' || doubleResolution">
              <v-col
                  cols="12"
              >
                <v-row>
                  <v-col cols="1">
                    <v-select
                        v-model="invoiceRef"
                        :items="['A', 'F']"
                        @change="changeInvoiceRef"
                    />
                  </v-col>
                  <v-col cols="11">
                    <v-text-field
                        v-model="invoice"
                        counter="7"
                        type="number"
                        :error-messages="invoiceErrors"
                        @blur="$v.invoice.$touch()"
                        @input="$v.invoice.$touch()"
                    >
                      <template #label>
                        {{ $t('reject.resolve.fields.invoice') }}
                        <span class="red--text"><strong> *</strong></span>
                      </template>
                      <template #append-outer>
                    <span class="grey--text fact-inner-txt"> : {{
                        invoiceRef
                      }}-{{ addLeadingZeros(reject.customer.pointOfSale, 3) }} {{
                        computedZeroInvoice
                      }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
        <div class="my-3">
          <span>{{ $t('reject.resolve.addDocument') }}</span>
        </div>
        <v-data-table
            :headers="headersTab"
            :items="documents"
            :items-per-page="documents.length"
            :no-data-text="$t('reject.documents.dataTable.noData')"
            :expanded.sync="expanded"
            :sort-by="['name']"
            single-expand
            show-expand
            multi-sort
            hide-default-footer
        >
          <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
            <v-icon
                v-if="item.description"
                class="rotate"
                :class="isExpanded ? 'down': ''"
                @click="expand(!isExpanded)"
            >
              mdi-chevron-down
            </v-icon>
          </template>
          <template #expanded-item="{ headers, item }">
            <td
                v-if="item.description"
                :colspan="headers.length"
                class="py-3 px-5 desc-multi-line"
            >
              <div>{{ item.description }}</div>
            </td>
          </template>
          <template #[`item.name`]="{ item }">
            <span class="d-flex align-center">
              <FileIcons
                  :value="value"
                  size="sm"
                  :file-url="item.url"
              />
              <span class="pl-3">
                {{ item.name }}
              </span>
            </span>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-checkbox
                class="mr-2"
                @change="attachDocument(item)"
            />
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            :disabled="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            :loading="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { required, requiredUnless } from 'vuelidate/lib/validators'
import rejectService from '@/services/reject/rejectService'
import { maxLength } from 'vuelidate/lib/validators'
import FileIcons from '@/components/general/fileIcons/FileIcons.vue'

export default {
  name: 'ResolveReject',
  components: {
    FileIcons,
    FieldStates
  },
  props: ['value', 'reject', 'documents'],
  validations: {
    creditNote: {
      required,
      maxLength: maxLength(7)
    },
    invoice: {
      required: requiredUnless(function (nestedModel) {
        return this.reject.qualification == 'JUSTIFIED' && !this.doubleResolution
      }),
      maxLength: maxLength(7)
    },
    resolutionMessage: {
      required: requiredUnless(function (nestedModel) {
        return this.reject.qualification == 'JUSTIFIED'
      })
    }
  },
  data: () => ({
    INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    invoice: '',
    invoiceRef: 'F',
    creditNote: '',
    creditNoteRef: 'A',
    resolutionMessage: '',
    doubleResolution: false,
    expanded: [],
    documentsAttached: []
  }),
  computed: {
    invoiceErrors () {
      if (this.$v.invoice.$dirty) {
        if (!this.$v.invoice.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.invoice.maxLength) {
          return this.$tc('message.error.validation.maxlength', 7)
        }
      }
      return []
    },
    creditNoteErrors () {
      if (this.$v.creditNote.$dirty) {
        if (!this.$v.creditNote.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.creditNote.maxLength) {
          return this.$tc('message.error.validation.maxlength', 7)
        }
      }
      return []
    },
    computedZeroInvoice () {
      return this.addLeadingZeros(this.invoice, 7)
    },
    computedZeroCreditNote () {
      return this.addLeadingZeros(this.creditNote, 7)
    },
    headersTab () {
      return [
        {
          text: this.$t('reject.documents.dataTable.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('reject.card.informations.attach'),
          value: 'actions',
          sortable: false,
          class: 'actionsColumn',
          width: 140
        },
        {
          text: '',
          value: 'data-table-expand'
        }
      ]
    }
  },
  created () {
    this.resolutionMessage = this.reject.resolutionMessage
  },
  methods: {
    close () {
      this.$emit('close')
    },
    addLeadingZeros (num, totalLength) {
      return String(num).padStart(totalLength, '0')
    },
    computeFactAMEXFormat (firstLetter, pointOfSale, value) {
      return firstLetter + this.addLeadingZeros(pointOfSale, 3)[2] + this.addLeadingZeros(value, 7)
    },
    changeCreditNoteRef () {
      if (this.creditNoteRef === 'A') {
        this.invoiceRef = 'F'
      } else {
        this.invoiceRef = 'A'
      }
    },
    changeInvoiceRef () {
      if (this.invoiceRef === 'A') {
        this.creditNoteRef = 'F'
      } else {
        this.creditNoteRef = 'A'
      }
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (this.reject.qualification == 'JUSTIFIED' && !this.doubleResolution) {
          this.invoice = ''
        }

        let resInvoice = this.invoice
        if (resInvoice != '') {
          resInvoice = this.computeFactAMEXFormat(this.invoiceRef, this.reject.customer.pointOfSale, this.invoice)
        }
        const resCreditNote = this.computeFactAMEXFormat(this.creditNoteRef, this.reject.customer.pointOfSale, this.creditNote)
        rejectService.sendResolve(this.value, resCreditNote, resInvoice, this.resolutionMessage, this.documentsAttached).then(() => {
          this.state = INPUT_STATES.SAVED
          this.$emit('resolveConfirm')
          this.close()
        }).catch(() => {
          this.state = INPUT_STATES.ERROR
          this.message = this.$t('message.error.update')
        })
      }
    },
    attachDocument (item) {
      if (this.documentsAttached.includes(item)) {
        this.documentsAttached.splice(this.documentsAttached.indexOf(item), 1)
      } else {
        this.documentsAttached.push(item)
      }
    }
  }
}
</script>

<style scoped>
.fact-inner-txt {
  padding-top: 6px !important;
  width: 140px;
}

.multiline-text {
  white-space: pre-line;
}

.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

</style>
