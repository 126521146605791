<template>
  <v-dialog
      :value="dialog"
      max-width="600"
      @click:outside="closeDialog"
  >
    <v-card class="pa-5">
      <v-card-title>
        <v-row
            class="d-flex flex-column"
            dense
            align="end"
        >
          <v-btn
              icon
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-row
          class="d-flex flex-column py-4"
          align="center"
      >
        <span>{{ $t('reject.documents.addDocument.filesNotImported') }}</span>
      </v-row>
      <v-virtual-scroll
          :items="filesNotImported"
          height="150"
          item-height="50"
      >
        <template #default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-3 text--secondary">
                    {{ item.error }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'NotImportedFiles',
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    filesNotImported: {
      type: Array
    }
  },
  methods: {
    closeDialog () {
      this.$emit('update:dialog', false)
    }
  }
}
</script>

<style scoped>

</style>
